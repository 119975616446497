import React from 'react';
import { staffingStatusesData } from '../components/calendar/calendar-outline/CalendarOutline';
import {
  TastingStatuses,
  tastingStutusesData,
} from '../components/requests/requests-calendar-outline/RequestsCalendarOutline';

export const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export interface ISelectedDays {
  id: number;
  companyId: number;
  modifiedAt: string;
  status: string;
  comments: string;
  startdate: Date;
  lastdate: Date;
  color: string;
  Icon: React.FC<any>;
  maxCapacity?: number;
  disableDelete?: boolean;
}

export interface ICalendarCommon {
  selectedDays: ISelectedDays[];
  year: number;
  onYearChange?: (year: number) => any;
  onMonthChange?: (year: number, month: number) => any;
  onDateClickHandler?: (day: Date) => any;
  onDateDraggedHandler?: (dates: Date[]) => any;
  onEdit?: (event: ISelectedDays) => any;
  onDelete?: (event: ISelectedDays) => any;
  disabledDates?: Date[];
}

export interface IRenderDateCells {
  date: number;
  isDragged: boolean;
  month: number;
  isInDisabledDates: boolean;
  isDisabled: boolean;
  isSelected?: ISelectedDays;
  differenceInDays?: number;
  handleDateClick: (day: number, month: number) => any;
  handleMouseDown: (day: number, month: number) => any;
  handleMouseEnter: (day: number, month: number) => any;
  onDelete?: (selectedDate: ISelectedDays) => any;
  onEdit?: (selectedDate: ISelectedDays) => any;
  handleMouseUp: () => any;
}

export const createDateKey = (day: number, month: number) => {
  return `${month}-${day}`;
};

export const createNewDate = (year: number, month: number, date: number) => {
  return new Date(year, month, date);
};

export const getMonthAndDayFromDateKey = (dateKey: string) => {
  return dateKey.split('-').map(Number);
};

export const getDifferenceBetweenDates = (startDate: Date, endDate: Date) => {
  return (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
};

export const isValidDate = (formedDate: Date, dayParam: number) => {
  return formedDate.getDate() == dayParam;
};

export const convertISOToDate = (isoDateString: string): Date => {
  // Parse the year, month, and day from the ISO date string
  const year = parseInt(isoDateString.substring(0, 4), 10);
  const month = parseInt(isoDateString.substring(5, 7), 10) - 1; // 0-indexed month
  const day = parseInt(isoDateString.substring(8, 10), 10);

  // Create and return a new Date object with the year, month, and day
  return new Date(year, month, day);
};

export const getDateTimeString = (dateString: string): string => {
  // Parse the ISO date string into a Date object
  const date = new Date(dateString);

  // Extract the day and time components
  const day = date.getUTCDate();
  const month = date.toLocaleString('en-US', {
    month: 'short',
    timeZone: 'UTC',
  });
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  // Format the date as "17 Oct 08:22"
  return `${day} ${month} ${hours}:${minutes}`;
};

export const getDateYearString = (dateString: string): string => {
  // Parse the ISO date string into a Date object
  const date = toLocalDate(new Date(dateString));

  // Extract the day and time components
  const day = date.getUTCDate();
  const month = date.toLocaleString('en-US', {
    month: 'short',
    timeZone: 'UTC',
  });

  // Format the date as "17 Oct 08:22"
  return `${day} ${month} ${date.getFullYear()}`;
};

export const makeCalendarObject = (el: any) => ({
  id: el.id,
  companyId: el.companyId,
  status: el.status,
  modifiedAt: el.modifiedAt,
  startdate: convertISOToDate(el.startDate),
  lastdate: convertISOToDate(el?.endDate),
  color: staffingStatusesData[el.status].color,
  Icon: staffingStatusesData[el.status].Icon,
  comments: el.reason,
});

export const makeTastingObject = (el: any) => ({
  id: el.id,
  companyId: el.companyId,
  status: el.status,
  modifiedAt: el.modifiedAt,
  maxCapacity: el?.maxCapacity,
  startdate: convertISOToDate(el.bookingDate),
  lastdate: convertISOToDate(el.bookingDate),
  color: tastingStutusesData[el.status].color,
  Icon: tastingStutusesData[el.status].Icon,
  comments: el?.comments,
  disableDelete: el?.status == TastingStatuses.CANCELED ? true : false,
});

export const getDateFromDateKey = (dateKey: string, year: number) => {
  const [month, day] = getMonthAndDayFromDateKey(dateKey);
  const targetDate = createNewDate(year, month, day);
  return targetDate;
};
export function areDatesSameIgnoringTime(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function isDateInPast(date: Date): boolean {
  const currentDate = new Date();
  // Remove the time portion for an accurate date-only comparison
  currentDate.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  return date < currentDate;
}

export function toLocalDate(date: Date): Date {
  const offsetInMs = date.getTimezoneOffset() * 60 * 1000;
  const localDate = new Date(date.getTime() - offsetInMs);
  return localDate; // Removes the 'Z' at the end
}

export function isoStringToLocalDate(isoDateString?: string): Date {
  const date = new Date(isoDateString ?? '');
  if (isNaN(date.getTime())) {
    throw new Error('Invalid ISO date string');
  }
  const offsetInMs = date.getTimezoneOffset() * 60 * 1000;
  const localDate = new Date(date.getTime() - offsetInMs);
  return localDate; // Returns a Date object adjusted to local time
}
