import LeftArrow from '../../../assets/icons/left-arrow-new.svg';
import VoucherOrder from '../voucher-order/VoucherOrder';
import './VoucherCheckout.scss';

const VoucherCheckout = ({ toggleCheckoutSectionVisibility }: any) => {
  return (
    <div className='voucher-checkout'>
      <div className='voucher-checkout-header'>
        <div className='voucher-checkout-header__container'>
          <img
            src={LeftArrow}
            alt=''
            srcSet=''
            onClick={() => toggleCheckoutSectionVisibility(false)}
          />
          <p>Vouchers</p>
        </div>
      </div>
      <VoucherOrder />
    </div>
  );
};

export default VoucherCheckout;
