import { gql, useMutation } from '@apollo/client';

type SpecialOrderItemsArgs = {
  productId: number;
  quantity: number;
};

const UPADTE_ORDER = gql`
  mutation UpdateCompanySpecialOrder(
    $specialOrderId: Int!
    $specialOrderItems: [SpecialOrderItemsArgs!]!
  ) {
    updateCompanySpecialOrder(
      updateCompanySpecialOrderArgs: {
        specialOrderItems: $specialOrderItems
        specialOrderId: $specialOrderId
      }
    ) {
      message
      status
      statusCode
      error
      data {
        specialOrderId
      }
    }
  }
`;

export const useUpdateSpecialOrder = () => {
  const [updateSpecialOrderQuery, { error, data, loading }] =
    useMutation(UPADTE_ORDER);

  const updateSpecialOrder = (
    specialOrderId: number,
    items: any[],
    onCompleteCb?: (data: any) => any,
  ) => {
    updateSpecialOrderQuery({
      variables: {
        specialOrderId,
        specialOrderItems: items,
      },
      onCompleted: onCompleteCb,
    });
  };

  return { updateSpecialOrder, error, data, updateOrderLoading: loading };
};
