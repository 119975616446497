import React, { useState } from 'react';
import Warning from '../../../assets/icons/red-warning.svg';
import RightTick from '../../../assets/icons/right-tick.svg';
import { useGlobalContext } from '../../../global-context/GlobalContext';
import { SpecialProduct } from '../../../interfaces/ISpecialOrder';
import DrawerCommon from '../../common/drawer-common/DrawerCommon';
import ProductCard from '../product-card/ProductCard';
import QuantitySelector from '../quantity-selector/QuantitySelector';
import './FoodCard.scss';
interface FoodCardProps {
  foodInfo: SpecialProduct;
}

const baseUrl = process.env.REACT_APP_BASE_URL_IMAGE;
const FoodCard: React.FC<FoodCardProps> = ({ foodInfo }) => {
  const quantity =
    useGlobalContext(c => c.specialOrder.cartItems).get(foodInfo.productId)
      ?.cartQuantity ?? 0;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <div className='food-card'>
        <div
          className='food-card__image-container'
          onClick={() => setIsDrawerOpen(true)}
        >
          <img
            src={`${baseUrl}${foodInfo.productImage}`}
            alt={foodInfo.productName}
            className='food-card__image'
          />
          {foodInfo.isAllergic && (
            <div className='food-card-allergy'>
              {foodInfo.allergicItems.map((allergy, index) => (
                <img
                  src={`${baseUrl}${allergy.image}`}
                  alt={allergy.name}
                  className='food-card-allergy__img'
                  key={index}
                />
              ))}
            </div>
          )}
          <div className='food-card__image-container-header left'>
            {foodInfo.isAllergic && <img src={Warning} />}
          </div>
          <div className='food-card__image-container-header right'>
            {quantity > 0 && <img src={RightTick} />}
          </div>
        </div>
        <div className='food-card__details'>
          <div className='food-card__details-info'>
            <h2 className='food-card__title'>{foodInfo.productName}</h2>
            <p className='food-card__price'>CHF {foodInfo.price.toFixed(2)}</p>
            <div className='food-card__icons'>
              {foodInfo.productFeatures.map((info, index) => (
                <img
                  src={`${baseUrl}${info.image}`}
                  alt={info.name}
                  className='food-card__icons-img'
                  key={index}
                />
              ))}
            </div>
          </div>

          <QuantitySelector quantity={quantity} foodInfo={foodInfo} />
        </div>
      </div>

      <AddEditDetailsDrawer
        isOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        foodInfo={foodInfo}
      />
    </>
  );
};

const AddEditDetailsDrawer = ({ isOpen, foodInfo, setIsDrawerOpen }: any) => {
  return (
    <DrawerCommon
      isVisible={isOpen}
      setVisibility={setIsDrawerOpen}
      placement='right'
      styles={{ width: '30%', maxWidth: '30rem' }}
    >
      <ProductCard setIsDrawerOpen={setIsDrawerOpen} foodInfo={foodInfo} />
    </DrawerCommon>
  );
};

export default FoodCard;
