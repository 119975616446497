import { T, useTolgee, useTranslate } from '@tolgee/react';
import React, { useState } from 'react';
import ArrowImage from '../../../assets/icons/down-arrow.svg';
import useGetMasterAllergies from '../../../hooks/graphql/useGetMasterAllergies';
import { useEffectOnce } from '../../../hooks/utils/useEffectOnce';
import AllergyFilter from '../allergy-filter/AllergyFilter';
import './SpecialOrderMealSelector.scss';
type IMealType = 'Main' | 'Drinks' | 'Deserts' | 'Others';

const MealType = [
  { value: 'Main', label: <T keyName='main' /> },
  { value: 'Drinks', label: <T keyName='drinks' /> },
  { value: 'Deserts', label: <T keyName='deserts' /> },
  { value: 'Others', label: <T keyName='others' /> },
];

interface SpecialOrderMealSelectorProps {
  onSelect: (mealType: IMealType) => void;
  onAllergiesSelect: (allergy: number) => void;
  selectedAllergies: any;
}

const SpecialOrderMealSelector: React.FC<SpecialOrderMealSelectorProps> = ({
  onSelect,
  onAllergiesSelect,
  selectedAllergies,
}) => {
  const [selectedMeal, setSelectedMeal] = useState<any>('Main');
  const [filter, setFilter] = useState(false);
  const [openAllergies, setOpenAllergies] = useState(false);

  const { getMasterAllergies, masterAllergies } = useGetMasterAllergies();
  const { getLanguage } = useTolgee();
  const { t } = useTranslate();

  useEffectOnce(() => {
    getMasterAllergies();
  });

  const handleSelect = (mealType: any) => {
    setSelectedMeal(mealType);
    onSelect(mealType);
  };

  return (
    <div className='meal-selector'>
      <div className='tabs'>
        {MealType.map(meal => (
          <div
            key={meal.value}
            className={`tab ${selectedMeal === meal.value ? 'tabs-active' : ''}`}
            onClick={() => handleSelect(meal.value)}
          >
            {meal.label}
          </div>
        ))}
      </div>
      <div
        className='filter-toggle'
        onClick={() => setOpenAllergies(!openAllergies)}
      >
        <label>{t('filter-by-intolerance')}</label>
        <span>{selectedAllergies.length}</span>
        <img src={ArrowImage} />
      </div>
      {openAllergies && (
        <AllergyFilter
          allergies={masterAllergies}
          language={getLanguage() as any}
          onAllergiesSelect={onAllergiesSelect}
          selectedAllergies={selectedAllergies}
        />
      )}
    </div>
  );
};

export default SpecialOrderMealSelector;
