import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { Button, DatePicker, Popover } from 'antd';
import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect, useState } from 'react';
import CartIcon from '../../../assets/icons/car.svg';
import CartIconDark from '../../../assets/icons/car2.svg';
import {
  useGlobalActions,
  useGlobalContext,
} from '../../../global-context/GlobalContext';
import { showToast } from '../../../utils/commonUtility';
import './SpecialOrderHeader.scss';

dayjs.extend(customParseFormat);

type SpecialOrderHeaderProps = {
  onDateSelect: (dateObj: any) => void; // Define the type for your prop
  selectedDate: any;
  toggleOrderSectionVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  orderSectionVisible: boolean;
};
const SpecialOrderHeader: React.FC<SpecialOrderHeaderProps> = ({
  onDateSelect,
  selectedDate,
  toggleOrderSectionVisibility,
  orderSectionVisible,
}) => {
  const [weekDays, setWeekDays] = useState<any[]>([]);
  const {
    itemsInCart: cartCount,
    specialOrderId,
    isExistingCartUpdated,
  } = useGlobalContext(c => c.specialOrder);
  const { clearCart } = useGlobalActions();

  const [datePopupOpenKey, setDatePopupOpenKey] = useState(null);
  const { t } = useTranslate();

  const generateWeekDates = (date: Dayjs) => {
    const monday = date.startOf('week').add(1, 'day'); // Adjust for Monday
    const weekDatesArray = [];
    for (let i = 0; i < 5; i++) {
      const currentDay = monday.add(i, 'day');
      weekDatesArray.push({
        title: currentDay.format('dddd D MMM'),
        day: i + 1,
        date: currentDay.toISOString(),
      });
    }
    return weekDatesArray;
  };

  const handleDateSelect = (date: Dayjs | null) => {
    console.log('date', date);
    if (!date) {
      console.error('No date selected');
      return;
    }
    const weekDates = generateWeekDates(date);
    console.log('weekDates', weekDates);
    const ValidDate = weekDates.find(d => d.date === date.toISOString());
    if (ValidDate) {
      setWeekDays(weekDates);
      onDateSelect(ValidDate);
    } else {
      setWeekDays(weekDates);
      onDateSelect(weekDates[0]);
    }
  };

  const handleCartClick = () => {
    if (orderSectionVisible && cartCount < 1) {
      showToast('add-to-cart-error', 'error');
      return;
    }
    toggleOrderSectionVisibility(prev => !prev);
  };

  useEffect(() => {
    const today = dayjs(); // Get current date
    const weekDates = generateWeekDates(today);
    setWeekDays(weekDates);

    // Call onDateSelect with today's date object
    const currentDayObject = weekDates.find(
      d => dayjs(d.date).format('YYYY-MM-DD') === today.format('YYYY-MM-DD'),
    );
    if (currentDayObject) {
      onDateSelect(currentDayObject);
    } else {
      onDateSelect(weekDates[0]);
    }
  }, []);

  const handleDateChange = (day: any) => {
    if (cartCount == 0 || (specialOrderId && !isExistingCartUpdated)) {
      onDateSelect(day);
    } else {
      setDatePopupOpenKey(day.day);
    }
  };
  const handleConfirmDateChange = (day: any) => {
    onDateSelect(day);
    setDatePopupOpenKey(null);
    clearCart();
  };
  const handleCancelDateChange = () => {
    setDatePopupOpenKey(null);
  };

  return (
    <div className='special-order-section-header'>
      {orderSectionVisible && (
        <>
          <DatePicker
            onChange={handleDateSelect}
            picker='week'
            defaultValue={dayjs()}
            disabledDate={current => {
              return current && current < dayjs().startOf('week');
            }}
            format={value => {
              // Custom formatting to exclude Saturday and Sunday
              const monday = value
                .startOf('week')
                .add(1, 'day')
                .format('ddd. DD/MM/YYYY'); // Monday
              const friday = value
                .startOf('week')
                .add(5, 'day')
                .format('ddd. DD/MM/YYYY'); // Friday
              return `${monday} - ${friday}`;
            }}
          />
          {selectedDate && (
            <>
              {weekDays.map((day, index) => (
                <div
                  key={index}
                  className='special-order-section-header__dates'
                >
                  <p onClick={() => handleDateChange(day)}>{day.title}</p>
                  {selectedDate.day === day.day && (
                    <div
                      className={`tab ${selectedDate.day === day.day ? 'tabs-active' : ''}`}
                    ></div>
                  )}
                  <Popover
                    content={
                      <div className='popover-content'>
                        <p className='message'>
                          {t('change-date-msg')}
                          {day.title}
                        </p>
                        <div className='actions'>
                          <Button
                            className='no-button'
                            onClick={() => handleCancelDateChange()}
                          >
                            No
                          </Button>
                          <Button
                            className='yes-button'
                            onClick={() => handleConfirmDateChange(day)}
                          >
                            Yes
                          </Button>
                        </div>
                      </div>
                    }
                    placement='bottom'
                    arrow={{ pointAtCenter: true }}
                    title=''
                    trigger='click'
                    visible={datePopupOpenKey === day.day} // Control visibility based on record key
                    onOpenChange={visible =>
                      setDatePopupOpenKey(visible ? day.day : null)
                    }
                    className='special-order-section-header__dates-popup'
                  ></Popover>
                </div>
              ))}
            </>
          )}
        </>
      )}
      {!orderSectionVisible && (
        <Button
          className='special-order-section-header__cart'
          style={{ right: '6rem' }}
          shape='circle'
          type='text'
          icon={<ArrowLeftOutlined />}
          onClick={() => toggleOrderSectionVisibility(true)}
        />
      )}
      <div
        className={classNames('special-order-section-header__cart', {
          'ordersection-visible': !orderSectionVisible,
        })}
        onClick={handleCartClick}
      >
        <img src={orderSectionVisible ? CartIcon : CartIconDark} alt='' />
        <p>{cartCount}</p>
      </div>
    </div>
  );
};

export default SpecialOrderHeader;
