import './Loader.scss';
function Loader() {
  return (
    <div className='simple-spinner'>
      <span></span>
    </div>
  );
}

export default Loader;
