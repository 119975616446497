import {
  ArrowLeftOutlined,
  CalendarOutlined,
  MessageFilled,
  MessageOutlined,
} from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { Button, Input, Typography } from 'antd';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGlobalActions } from '../../../global-context/GlobalContext';
import useIsMobile from '../../../hooks/utils/useIsMobile';
import { useStaffingService } from '../../../hooks/utils/useStaffingService';
import {
  daysOfWeek,
  getDateTimeString,
  getDateYearString,
  ISelectedDays,
} from '../../../utils/calendarUtility';
import { showToast } from '../../../utils/commonUtility';
import { SidebarSlider } from '../../common/sidebar-slider/SidebarSlider';
import './CalendarRemarks.scss';

const { TextArea } = Input;
const { Text } = Typography;
export const CalendarRemarks = ({
  refetchMainCalendarData,
}: {
  refetchMainCalendarData: () => any;
}) => {
  const {
    getStatusByPages,
    remarksData,
    updateStatus,
    deleteStatus,
    updateStatusLoading,
    deleteStatusLoading,
  } = useStaffingService();
  const { t } = useTranslate();
  const isMobile = useIsMobile();
  const [page, setPage] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [availableData, setAvailableData] = useState<any>([]);
  const fetchMoreData = () => {
    console.log('fetch more dat called');
    setPage(prev => prev + 1);
  };
  useEffect(() => {
    console.log('fetching data');
    getStatusByPages(page);
  }, [page]);
  useEffect(() => {
    console.log('new Data =', availableData);
    setAvailableData((prev: any) => [...prev, ...remarksData]);
    setHasMoreData(remarksData?.length ? true : false);
  }, [remarksData]);
  const { setOpenedRemarksPanelType } = useGlobalActions();

  return (
    <SidebarSlider
      width={isMobile ? '60%' : '25%'}
      visible={true}
      styles={{ backgroundColor: '#fbf7f7' }}
      setVisibility={() => {
        setOpenedRemarksPanelType('NONE');
      }}
    >
      <div style={{ margin: '2rem 0', background: '#fbf7f7' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 1rem',
          }}
        >
          <Text
            style={{
              fontSize: '1.6rem',
              margin: '0 0.6rem 0.6rem',
              color: '#275747',
            }}
          >
            {t('remarks')}
          </Text>
          <ArrowLeftOutlined
            onClick={() => setOpenedRemarksPanelType('NONE')}
            color='#275747'
            style={{ fontWeight: '900', cursor: 'pointer' }}
          />
        </div>
        <InfiniteScroll
          dataLength={availableData.length}
          next={fetchMoreData}
          hasMore={hasMoreData}
          scrollableTarget='sidebar-slider'
          loader={<>Loading</>}
        >
          {availableData.map((data: ISelectedDays, index: number) => (
            <DayCard
              key={data.id}
              data={data}
              deleteData={(id: number) => {
                deleteStatus(id, (data: any) => {
                  refetchMainCalendarData();
                  showToast('event-deleted', 'success');
                  setOpenedRemarksPanelType('NONE');
                });
              }}
              ctaLoading={deleteStatusLoading || updateStatusLoading}
              updateData={(
                statusid: number,
                status: string,
                reason: string,
                startDate: Date,
                endDate: Date,
              ) => {
                updateStatus(
                  statusid,
                  status,
                  reason,
                  startDate,
                  endDate,
                  (data: any) => {
                    showToast('event-updated', 'success');
                    refetchMainCalendarData();
                    setOpenedRemarksPanelType('NONE');
                  },
                );
              }}
            />
          ))}
        </InfiniteScroll>
      </div>
    </SidebarSlider>
  );
};

const DayCard: React.FC<{
  data: ISelectedDays;
  deleteData: (statusId: number) => void;
  updateData: (
    statusid: number,
    status: string,
    reason: string,
    startDate: Date,
    endDate: Date,
  ) => void;
  ctaLoading: boolean;
}> = ({ data, deleteData, updateData, ctaLoading }) => {
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState(data.comments);

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const handleSubmit = () => {
    updateData(data.id, data.status, comments, data.startdate, data.lastdate);
  };

  const handleDelete = () => {
    deleteData(data.id);
  };

  return (
    <div
      className='request-card'
      style={{ backgroundColor: !showComments ? '#fbf7f7' : '#ffff' }}
    >
      <div className='request-header'>
        <div className='icon-container' style={{ background: data.color }}>
          <data.Icon className='icon' />
        </div>

        <div className='request-details'>
          <strong className='status'>{data.status}</strong>
          <div className='date-info'>
            <CalendarOutlined /> {daysOfWeek[data.startdate.getDay()]}{' '}
            {getDateYearString(data.startdate.toLocaleDateString())}
          </div>
          <div className='time-info'>
            Updated {getDateTimeString(data.modifiedAt)}
          </div>
        </div>

        <div className='comments-toggle' onClick={toggleComments}>
          <span className='comments-text'>Comments</span>
          {data?.comments.length ? <MessageFilled /> : <MessageOutlined />}
        </div>
      </div>

      {showComments && (
        <div className='comments-section'>
          <textarea
            value={comments}
            onChange={e => setComments(e.target.value)}
            className='comments-input'
          />
          <div className='button-space'>
            {/* <Button
              className='delete-button'
              disabled={ctaLoading}
              onClick={handleDelete}
            >
              <DeleteOutlined />
            </Button> */}
            <Button
              className='submit-button'
              disabled={ctaLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
