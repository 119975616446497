import { gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

// @typescript-eslint/no-unused-vars
// TypeScript type for DatePeriod
type DatePeriod = {
  startDate: string;
  endDate: string;
};

// TypeScript type for Pagination
type Pagination = {
  page: number;
  limit: number;
};

// TypeScript type for Sort
type Sort = {
  name: string; // Field by which to sort, e.g., "startDate", "endDate"
  order: 'ASC' | 'DESC'; // Sorting order: Ascending or Descending
};

const GET_COMPANY_SALES = gql`
  query getCompanySales(
    $metric: Float!
    $filter: JSON
    $pagination: Pagination
    $sort: Sort
  ) {
    getCompanySales(
      getCompanySalesArgs: {
        metric: $metric
        filter: $filter
        pagination: $pagination
        sort: $sort
      }
    ) {
      message
      status
      statusCode
      error
      data {
        count
        page
        pageSize
        list {
          fridgeCompanyId
          fridgeCompanyName
          year
          month
          main
          deserts
          drinks
          others
          total
          comboDiscount
        }
        metric
        reportType
        base64String
      }
    }
  }
`;

export const useGetCompanySales = ({ metric }: { metric: number }) => {
  const [getCompanySales, { error, data, loading }] =
    useLazyQuery(GET_COMPANY_SALES);

  const fetchCompanySales = useCallback(
    (variables: any) => {
      getCompanySales({ variables: { metric, ...variables } });
    },
    [getCompanySales],
  );

  return {
    fetchCompanySales,
    error,
    companySales: data?.getCompanySales?.data,
    loading,
  };
};
