import { ErrorResponse } from '@apollo/client/link/error';

import { getGqlErrors, isNetworkError } from './getGraphqlError';

interface IGetNotificationsResponseParams {
  message: string;
  code?: string;
}
function getNotifications(
  error: ErrorResponse,
): IGetNotificationsResponseParams[] {
  if (isNetworkError(error)) {
    return [{ message: 'Check your network connection' }];
  }

  return getGqlErrors(error).map(({ message, extensions, originalError }) => ({
    message: (message || originalError?.message || '') as string,
    code: extensions?.code as string,
  }));
}

export default getNotifications;
