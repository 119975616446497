import { DatePicker } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGetCompanySales } from '../../../hooks/graphql/useCompanySales';
import useGetAssociatedCompanies from '../../../hooks/graphql/useGetAssociatedCompanies';
import { useGetCompanySalesMetric } from '../../../hooks/graphql/useGetCompanySalesMetric';
import usePdfGenerator from '../../../hooks/utils/usePdfGenerator';
import AnalyticsChartSection from '../analytics-chart-section/AnalyticsChartSection';
import AnalyticsSalesTable from '../analytics-sales-table/AnalyticsSalesTable';
import './AnalyticsSection.scss';

import dayjs from 'dayjs';
import DownloadIcon from '../../../assets/icons/download.svg';

const { RangePicker } = DatePicker;

const AnalyticsSection: React.FC = () => {
  const { contentRef, generatePDF } = usePdfGenerator();

  const startMontDate = dayjs().startOf('month').toISOString();
  const currentDate = dayjs().toISOString();

  const { associatedCompanies, getAssociatedCompanies } =
    useGetAssociatedCompanies();

  const [associatedCompaniesIds, setAssociatedCompaniesIds] = useState<
    number[]
  >([]);

  const salesData1 = useGetCompanySales({ metric: 1 });
  const salesData2 = useGetCompanySales({ metric: 2 });

  // Declare each metric hook at the top level
  const salesMetric3 = useGetCompanySalesMetric({ metric: 3 });
  const salesMetric4 = useGetCompanySalesMetric({ metric: 4 });
  const salesMetric5 = useGetCompanySalesMetric({ metric: 5 });
  const salesMetric6 = useGetCompanySalesMetric({ metric: 6 });
  const salesMetric7 = useGetCompanySalesMetric({ metric: 7 });
  const salesMetric8 = useGetCompanySalesMetric({ metric: 8 });
  const salesMetric9 = useGetCompanySalesMetric({ metric: 9 });
  const salesMetric10 = useGetCompanySalesMetric({ metric: 10 });

  // Collect all metric hooks into an array
  const salesMetrics = [
    salesMetric3,
    salesMetric4,
    salesMetric5,
    salesMetric6,
    salesMetric7,
    salesMetric8,
    salesMetric9,
    salesMetric10,
  ];

  const handleDateChange = useCallback(
    (dates: any) => {
      if (dates && dates[0] && dates[1]) {
        const startDate = dates[0].startOf('day').toISOString();
        const endDate = dates[1].endOf('day').toISOString();
        fetchAllData(associatedCompaniesIds, startDate, endDate);
      }
    },
    [associatedCompaniesIds],
  );

  useEffect(() => {
    getAssociatedCompanies();
  }, [getAssociatedCompanies]);

  useEffect(() => {
    if (associatedCompanies) {
      const companyIds = associatedCompanies.map(
        (company: any) => company.companyId,
      );
      setAssociatedCompaniesIds(companyIds);
      fetchAllData(companyIds);
    }
  }, [associatedCompanies]);

  const fetchAllData = useCallback(
    (companyIds: number[], startDate?: string, endDate?: string) => {
      const dateRange = {
        startDate: startDate ?? startMontDate,
        endDate: endDate ?? currentDate,
      };

      salesData1.fetchCompanySales({
        filter: { fridgeCompanyIds: companyIds, saleDateRange: dateRange },
        pagination: { page: null, limit: null },
      });

      salesData2.fetchCompanySales({
        filter: { fridgeCompanyIds: companyIds, saleDateRange: dateRange },
        pagination: { page: null, limit: null },
      });

      // Trigger data fetch for each metric hook
      salesMetrics.forEach(salesMetric => {
        salesMetric.fetchMetricData({
          filter: { fridgeCompanyIds: companyIds, saleDateRange: dateRange },
          pagination: { page: null, limit: null },
        });
      });
    },
    [salesData1, salesData2, salesMetrics],
  );

  return (
    <div className='analytics-section-container'>
      <div className='analytics-section-header'>
        <RangePicker
          onChange={handleDateChange}
          format='YYYY-MM-DD'
          style={{ width: '100%' }}
          defaultValue={[dayjs(startMontDate), dayjs(currentDate)]}
        />
        <div
          className='analytics-section-header-download'
          onClick={generatePDF}
        >
          <img src={DownloadIcon} alt='' />
          <p>Download</p>
        </div>
      </div>

      <div ref={contentRef}>
        <AnalyticsSalesTable
          table1={salesData1?.companySales?.list ?? []}
          table1Loading={salesData1.loading}
          table2Loading={salesData2.loading}
          table2={salesData2?.companySales?.list ?? []}
        />

        <div className='analytics-section-chart-container'>
          {salesMetrics
            .reduce((result: any, metric, index) => {
              // Create a new group every two items
              if (index % 2 === 0) {
                result.push([]);
              }
              result[result.length - 1].push(metric);
              return result;
            }, [])
            .map((chunk: any, chunkIndex: number) => (
              <div key={chunkIndex} className='analytics-section-chart-group'>
                {chunk.map((metric: any, index: number) => (
                  <AnalyticsChartSection
                    key={index}
                    data={metric.salesData?.list ?? []}
                    chartType={chunkIndex * 2 + index === 0 ? 'pie' : 'bar'}
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsSection;
