import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useRef } from 'react';

interface PdfOptions {
  frontPageTitle?: string;
  frontPageSubtitle?: string;
}

const usePdfGenerator = () => {
  const contentRef = useRef<HTMLDivElement>(null);

  const generatePDF = async () => {
    const doc = new jsPDF('p', 'mm', 'a4'); // Initialize jsPDF
    const pageWidth = doc.internal.pageSize.getWidth(); // PDF page width
    const pageHeight = doc.internal.pageSize.getHeight(); // PDF page height

    // Ensure the contentRef is valid
    if (contentRef.current) {
      const contentDivs = Array.from(contentRef.current.children); // Top-level divs

      for (const [index, div] of contentDivs.entries() as any) {
        if (index === 0) {
          // Special handling for the first div
          const childElements = Array.from(div.children); // Children of the first div

          for (const [childIndex, child] of childElements.entries() as any) {
            const canvas = await html2canvas(child as HTMLElement, {
              scale: 2,
            });
            const imgData = canvas.toDataURL('image/png');
            const imgHeight = (canvas.height * pageWidth) / canvas.width;

            doc.addImage(imgData, 'PNG', 0, 0, pageWidth, imgHeight);

            // Add a page unless it's the last child
            console.log('childElements', childIndex, childElements.length - 1);
            if (childIndex < childElements.length) {
              doc.addPage();
            }
          }
        } else {
          // For other divs, render them on a single page each
          const canvas = await html2canvas(div as HTMLElement, { scale: 2 });
          const imgData = canvas.toDataURL('image/png');
          const imgHeight = (canvas.height * pageWidth) / canvas.width;

          doc.addImage(imgData, 'PNG', 0, 0, pageWidth, imgHeight);

          // Add a page unless it's the last div
          if (index < contentDivs.length - 1) {
            doc.addPage();
          }
        }
      }
    }

    // Save the PDF
    doc.save('download.pdf');
  };

  return { contentRef, generatePDF };
};

export default usePdfGenerator;
