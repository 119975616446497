import { useMemo } from 'react';
import { makeCalendarObject } from '../../utils/calendarUtility';
import { useAddStaffingStatus } from '../graphql/useAddStaffingStatus';
import { useDeleteStaffingStatus } from '../graphql/useDeleteStaffingStatus';
import { useGetStaffingStatus } from '../graphql/useGetStaffingStatus';
import { useUpdateStaffingStatus } from '../graphql/useUpdateStaffingStatus';
let datesVariables: {
  filter: { datePeriod: { startDate: string; endDate: string } };
};
let pageVariables: {
  pagination: {
    page: number;
    limit: number;
  };
  sort: {
    name: string;
    order: 'ASC' | 'DESC';
  };
};
export const useStaffingService = () => {
  const {
    getCompanyStatuses: getStatusByDates,
    // error: getStatusByDatesError,
    data: getStatusByDateData,
    loading: getStatusByDatesLoading,
  } = useGetStaffingStatus();
  const {
    getCompanyStatuses: getStatusByPage,
    error: getStatusByPageError,
    data: getStatusByPageData,
    loading: getStatusByPageLoading,
  } = useGetStaffingStatus();
  const { addStatus, loading: addStatusLoading } = useAddStaffingStatus();
  const { updateStatus, loading: updateStatusLoading } =
    useUpdateStaffingStatus();
  const { deleteStatus, loading: deleteStatusLoading } =
    useDeleteStaffingStatus();

  const getStatusBtwDates = (startDate: Date, endDate: Date) => {
    datesVariables = {
      filter: {
        datePeriod: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      },
    };
    getStatusByDates({ variables: datesVariables });
  };
  const refetchGetStatusBtwDates = () => {
    if (datesVariables) getStatusByDates({ variables: datesVariables });
  };

  const getStatusByPages = (page: number, limit: number = 10) => {
    pageVariables = {
      pagination: {
        page: page,
        limit: limit,
      },
      sort: {
        name: 'modifiedAt',
        order: 'DESC',
      },
    };
    getStatusByPage({ variables: pageVariables });
  };

  const calendarData = useMemo(() => {
    const list = getStatusByDateData?.companyStaffingStatuses?.data?.list;
    if (!list) {
      return [];
    }
    return list.map(makeCalendarObject);
  }, [getStatusByDateData]);

  const remarksData = useMemo(() => {
    const list = getStatusByPageData?.companyStaffingStatuses?.data?.list;
    if (!list) {
      return [];
    }
    return list.map(makeCalendarObject);
  }, [getStatusByPageData]);

  return {
    getStatusBtwDates,
    refetchGetStatusBtwDates,
    getStatusByPages,
    getStatusByDatesLoading,
    remarksData,
    calendarData,
    addStatusLoading,
    updateStatusLoading,
    addStatus,
    updateStatus,
    deleteStatus,
    deleteStatusLoading,
    getStatusByPageLoading,
  };
};
