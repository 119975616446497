import { useState } from 'react';
import localStorageService from '../../services/LocalStorage';
import { tokenService } from '../../services/TokenService';
import { showToast } from '../../utils/commonUtility';
import useCustomerSignIn from '../graphql/useCustomerSignIn';
import useGetCompany from '../graphql/useGetCompany';

interface CompanyData {
  abbreviatedCompanyName: string;
  companyCategoryId: string;
  companyCode: string;
  companyColourCode: string;
  companyId: number;
  isArchived: number;
  logo: string;
}


const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [companyInfo, setCompanyInfo] = useState<CompanyData | null>(null);
  const [needToVerifyToken, setNeedToVerify] = useState(
    Boolean(tokenService.getToken()),
  );
  const { signIn, loading: signInLoading } = useCustomerSignIn();
  const {
    getCompanyProfile,
    error: companyError,
    loading: companyLoading,
  } = useGetCompany();

  const handleAfterLogin = async (accessToken: string) => {
    if (!accessToken) {
      showToast('fail-login', 'error', '', 'topLeft');
      return;
    }
    showToast('success-login', 'success', '', 'topLeft');
    tokenService.setToken(accessToken!);
    setIsAuthenticated(true);
  };
  const loginCta = async (email: string, appName: string, password: string) => {
    const res = await signIn(email, appName, password);
    handleAfterLogin(res.data.employeeSignIn.data.token);
    setCompanyInfo(res?.data?.employeeSignIn?.data?.company);
  };

  const verifyUser = async () => {
    if (needToVerifyToken) {
      const res = await getCompanyProfile();
      if (res?.data) {
        setIsAuthenticated(true);
        setCompanyInfo(res?.data?.getCompany?.data);
      } else {
        tokenService.disposeToken();
      }
    }
    setNeedToVerify(false);
  };

  const clearUserSession = () => {
    tokenService.disposeToken();
    localStorageService.clear();
    setIsAuthenticated(false);
  };

  const state = {
    isAuthenticated,
    needToVerifyToken,
    signInLoading,
    companyError,
    companyLoading,
    companyInfo,
  };

  const actions = {
    loginCta,
    verifyUser,
    clearUserSession,
  };
  return { state, actions };
};

export default useAuth;
