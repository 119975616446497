import { ErrorResponse } from '@apollo/client/link/error';

import { LINKS } from '../routes/Links';
import { navigateTo, showToast } from './commonUtility';
import getNotifications from './getNotifications';

interface OperationContext {
  disableErrorNotification?: boolean;
}

function globalErrorHandler(error: ErrorResponse) {
  const { disableErrorNotification } =
    error.operation.getContext() as OperationContext;
  if (!disableErrorNotification) {
    getNotifications(error).forEach(notification => handleError(notification));
  }
}

// This identity function is used for context typing
function context(operationContext: OperationContext): OperationContext {
  return operationContext;
}
const handleError = (err: any) => {
  const { code } = err;
  console.log(err);
  switch (code) {
    case 'UNAUTHENTICATED':
      showToast('session-expired', 'error', 'login-again');
      navigateTo(LINKS.LOGOUT.build());
      return
    case 'INTERNAL_SERVER_ERROR':
      showToast(err?.message, 'error')
      break
  }
};
export { context, globalErrorHandler };

