import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { T, useTranslate } from '@tolgee/react';
import { Button, Input, Popover, Select, Table, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import useGetAssociatedCompanies from '../../../hooks/graphql/useGetAssociatedCompanies';
import { useGetCompanyUsers } from '../../../hooks/graphql/useGetCompanyUsers';
import { useUpdateUserStatus } from '../../../hooks/graphql/useUpdateUserStatus';
import { useEffectOnce } from '../../../hooks/utils/useEffectOnce';
import { debounce } from '../../../utils/debounce';
import './UserListSection.scss';

interface User {
  key: number;
  firstName: string;
  lastName: string;
  email: string;
  status: 'Actif' | 'Inactif';
}
interface IAssociatedCompanies {
  abbreviatedCompanyName: string;
  companyId: number;
  companyName: string;
}

const UserListSection: React.FC = () => {
  const { t } = useTranslate();
  const [visiblePopoverKey, setVisiblePopoverKey] = useState(null);
  const [associatedCompaniesValue, setAssociatedCompaniesValue] = useState([]);
  const [searchText, setSearchText] = useState<string>('');
  const { associatedCompanies, getAssociatedCompanies } =
    useGetAssociatedCompanies();
  const { companyUsers, getCompanyUsers, loading } = useGetCompanyUsers();
  const { updateStatus, loading: updateStatusLoading } = useUpdateUserStatus();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const updateUserStatus = (employeeId: any, active: boolean) => {
    updateStatus(Number(employeeId), !active, (data: any) => {
      console.log('data', data);
      setVisiblePopoverKey(null);
      const companyIds = getCompanyIds(associatedCompaniesValue);
      getCompanyUsers({
        variables: {
          filter: {
            companyId: companyIds,
            search: searchText,
          },
          pagination: { page: pagination.current, limit: pagination.pageSize },
          sort: { name: 'familyName', order: 'ASC' },
        },
        fetchPolicy: 'network-only', // Forces re-fetch from the server
      });
    });
  };

  useEffectOnce(() => {
    getAssociatedCompanies();
  });

  useEffect(() => {
    if (associatedCompanies) {
      const companyIds = associatedCompanies.map((com: any) => com.companyId);
      setAssociatedCompaniesValue(
        associatedCompanies?.map((compa: any) => compa.companyName),
      );

      getCompanyUsers({
        variables: {
          filter: {
            companyId: companyIds,
            search: searchText,
          },
          pagination: { page: 1, limit: 10 },
          sort: { name: 'familyName', order: 'ASC' },
        },
      });
    }
  }, [associatedCompanies]);

  useEffect(() => {
    if (companyUsers) {
      console.log('company user', companyUsers?.list);
    }
  }, [companyUsers]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    debouncedSearch(value);
  };

  const handlePopoverVisible = (key: any) => {
    console.log('key', key);
    setVisiblePopoverKey(key);
  };

  const debouncedSearch = useCallback(
    debounce(value => {
      const companyIds = getCompanyIds(associatedCompaniesValue);
      getCompanyUsers({
        variables: {
          filter: {
            companyId: companyIds,
            search: value,
          },
          pagination: { page: pagination.current, limit: pagination.pageSize },
          sort: { name: 'familyName', order: 'ASC' },
        },
      });
    }, 300), // Adjust debounce delay as needed
    [associatedCompanies, associatedCompaniesValue],
  );

  const handleAssociatedCompanyChange = (eventValue: any) => {
    setAssociatedCompaniesValue(eventValue);

    const companyIds = getCompanyIds(eventValue);
    getCompanyUsers({
      variables: {
        filter: {
          companyId: companyIds,
          search: searchText,
        },
        pagination: { page: pagination.current, limit: pagination.pageSize },
        sort: { name: 'familyName', order: 'ASC' },
      },
    });
  };

  const handlePageChange = (newPagination: any) => {
    const companyIds = getCompanyIds(associatedCompaniesValue);
    setPagination({
      ...pagination,
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
    getCompanyUsers({
      variables: {
        filter: {
          companyId: companyIds,
          search: searchText,
        },
        pagination: {
          page: newPagination.current,
          limit: newPagination.pageSize,
        },
        sort: { name: 'familyName', order: 'ASC' },
      },
    });
  };

  const handlePageSizeChange = (pageNo: number, pageSize: number) => {
    const companyIds = getCompanyIds(associatedCompaniesValue);
    console.log('handlePageSizeChange', pageNo, pageSize);

    getCompanyUsers({
      variables: {
        filter: {
          companyId: companyIds,
          search: searchText,
        },
        pagination: { page: pageNo, limit: pageSize },
        sort: { name: 'familyName', order: 'ASC' },
      },
    });
  };

  const columns = [
    {
      title: <T keyName='name' />,
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: <T keyName='first-name' />,
      dataIndex: 'familyName',
      key: 'familyName',
    },
    {
      title: <T keyName='email-address' />,
      dataIndex: 'email',
      key: 'email',
      //   render: (text: string) => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: <T keyName='account-status' />,
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean, record: any) => (
        <Popover
          content={
            <div className='popover-content'>
              <p className='message'>
                <ExclamationCircleOutlined className='icon' />
                Please confirm that you want to{' '}
                {active ? 'deactivate' : 'activate'} this account
              </p>
              <div className='actions'>
                <Button
                  className='no-button'
                  onClick={() => setVisiblePopoverKey(null)} // Close popover on "No" click
                >
                  No
                </Button>
                <Button
                  className='yes-button'
                  onClick={() => updateUserStatus(record.employeeId, active)}
                >
                  Yes
                </Button>
              </div>
            </div>
          }
          title=''
          trigger='click'
          visible={visiblePopoverKey === record.employeeId} // Control visibility based on record key
          onOpenChange={visible =>
            handlePopoverVisible(visible ? record.employeeId : null)
          }
        >
          <Tag color={active ? 'green' : 'red'} style={{ cursor: 'pointer' }}>
            {active ? 'Active' : 'Inactive'}
          </Tag>
        </Popover>
      ),
    },
  ];

  const getCompanyIds = (companyNames: string[]) => {
    return associatedCompanies
      .filter((comp: any) =>
        companyNames.find((val: string) => val === comp.companyName),
      )
      .map((com: any) => com.companyId);
  };

  return (
    <div className='user-list-section-container'>
      <div className='user-list-company-container'>
        <div className='user-list-company-profile'>
          <img src='' alt='' />
        </div>
        <h1>{t('list-of-employees')}</h1>
      </div>
      <div className='user-list-header-container'>
        <Input
          className='user-list-search-bar'
          placeholder={t('name-search-text')}
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={handleSearch}
        />
        <Select
          mode='multiple'
          maxTagCount='responsive'
          className='user-list-select-bar'
          value={associatedCompaniesValue}
          showSearch
          placeholder={t('select-a-company')}
          filterOption={(input, option) =>
            (option?.companyName ?? '')
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={associatedCompanies}
          fieldNames={{ label: 'companyName', value: 'companyName' }}
          onChange={handleAssociatedCompanyChange}
        />
      </div>
      <div className='user-list-table-container'>
        <Table
          columns={columns}
          dataSource={companyUsers?.list}
          bordered={true}
          loading={loading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {
              setPagination({ ...pagination, pageSize: size });
              handlePageSizeChange(current, size);
            },
            total: companyUsers?.count ?? 1,
          }}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default UserListSection;
