import { gql, useMutation } from '@apollo/client';

type SpecialOrderItemsArgs = {
  productId: number;
  price: number;
  quantity: number;
};

const CREATE_ORDER = gql`
  mutation CreateCompanySpecialOrder(
    $weekDate: DateTime!
    $day: Int!
    $items: [SpecialOrderItemsArgs!]!
  ) {
    createCompanySpecialOrder(
      createCompanySpecialOrderArgs: {
        weekDate: $weekDate
        day: $day
        specialOrderItems: $items
      }
    ) {
      message
      status
      statusCode
      error
      data {
        specialOrderId
      }
    }
  }
`;

export const useCreateSpecialOrder = () => {
  const [createSpecialOrderQuery, { error, data, loading }] =
    useMutation(CREATE_ORDER);

  const createSpecialOrder = (
    weekDate: string,
    day: number,
    items: any[],
    onCompleteCb?: (data: any) => any,
  ) => {
    createSpecialOrderQuery({
      variables: {
        weekDate,
        day,
        items,
      },
      onCompleted: onCompleteCb,
    });
  };

  return { createSpecialOrder, error, data, createOrderLoading: loading };
};
