import React from 'react';
import holidaysvg from '../../../assets/icons/Feries (1).svg';
import closedsvg from '../../../assets/icons/Ferme (1).svg';
import reductionsvg from '../../../assets/icons/Reduction (1).svg';
import pholidaysvg from '../../../assets/icons/Vacances (1).svg';

interface CreateIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  iconSrc: string;
}

export const CreateIcon: React.FC<CreateIconProps> = ({
  iconSrc,
  style,
  ...rest
}) => {
  return (
    <img
      src={iconSrc}
      style={{
        width: '80%',
        height: '80%',
        objectFit: 'contain',
        ...style,
      }}
      {...rest}
    />
  );
};

export const ClosedIcon: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement>
> = props => {
  return <CreateIcon iconSrc={closedsvg} {...props} />;
};

export const ReductionIcon: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement>
> = props => {
  return <CreateIcon iconSrc={reductionsvg} {...props} />;
};

export const PHolidaysIcon: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement>
> = props => {
  return <CreateIcon iconSrc={holidaysvg} {...props} />;
};

export const HolidaysIcon: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement>
> = props => {
  return <CreateIcon iconSrc={pholidaysvg} {...props} />;
};
