import { useState } from 'react';
import { SpecialProduct } from '../../interfaces/ISpecialOrder';

export interface productInCart extends SpecialProduct {
  cartQuantity: number;
  quantityCost: number;
}

const useSpecialOrder = () => {
  const [cartItems, setCartItems] = useState<Map<number, productInCart>>(
    new Map(),
  );
  const [existingCart, setExistingCart] = useState<Map<number, productInCart>>(
    new Map(),
  );
  const [specialOrderId, setSpecialOrderId] = useState<number | null>(null);

  const addToCart = (item: SpecialProduct) => {
    setCartItems(prevCartItems => {
      const updatedCart = new Map(prevCartItems);
      if (updatedCart.has(item.productId)) {
        const existingItem = updatedCart.get(item.productId)!;
        existingItem.cartQuantity = (existingItem.cartQuantity || 0) + 1;
        existingItem.quantityCost =
          existingItem.cartQuantity * existingItem.price;
      } else {
        updatedCart.set(item.productId, {
          ...item,
          cartQuantity: 1,
          quantityCost: item.price,
        });
      }

      return updatedCart;
    });
  };
  const setExistingOrderOnDay = (data: productInCart[], orderId = 0) => {
    setCartItems(() => {
      const existingCart = new Map();
      let specialOrderId = null;
      if (orderId) {
        specialOrderId = orderId;
        data.forEach(item => {
          if (item.cartQuantity > 0) {
            existingCart.set(item.productId, {
              ...item,
              specialOrderQuantity: item.cartQuantity,
              quantityCost: item.cartQuantity * item.price,
            });
          }
        });
      } else {
        data.forEach(item => {
          if (item.specialOrderQuantity > 0) {
            specialOrderId = item.specialOrderId;
            existingCart.set(item.productId, {
              ...item,
              cartQuantity: item.specialOrderQuantity,
              quantityCost: item.specialOrderQuantity * item.price,
            });
          }
        });
      }
      setSpecialOrderId(specialOrderId);
      setExistingCart(existingCart);
      return existingCart;
    });
  };
  const removeFromCart = (productId: number) => {
    setCartItems(prevCartItems => {
      const updatedCart = new Map(prevCartItems);
      if (updatedCart.has(productId)) {
        const existingItem = updatedCart.get(productId)!;
        if (existingItem.cartQuantity && existingItem.cartQuantity > 1) {
          existingItem.cartQuantity -= 1;
          existingItem.quantityCost =
            existingItem.cartQuantity * existingItem.price;
        } else {
          updatedCart.delete(productId);
        }
      } else {
        console.log(`Item with ID ${productId} is not in the cart.`);
      }

      return updatedCart;
    });
  };

  const clearCart = () => {
    setCartItems(new Map());
  };

  const state = {
    cartItems,
    itemsInCart: Array.from(cartItems.values()).reduce(
      (total, item) => total + (item.cartQuantity || 0),
      0,
    ),
    totalCartCost: Array.from(cartItems.values()).reduce(
      (total, item) => total + (item.quantityCost || 0),
      0,
    ),
    specialOrderId,
    isExistingCartUpdated: !areCartsEqual(cartItems, existingCart),
  };

  const actions = {
    addToCart,
    removeFromCart,
    clearCart,
    setExistingOrderOnDay,
    setExistingCart,
  };
  return { state, actions };
};
const areCartsEqual = (
  cart1: Map<number, productInCart>,
  cart2: Map<number, productInCart>,
): boolean => {
  if (cart1.size !== cart2.size) {
    return false;
  }
  for (const [productId, product] of cart1.entries()) {
    const matchingProduct = cart2.get(productId);
    if (
      !matchingProduct ||
      product.cartQuantity !== matchingProduct.specialOrderQuantity
    ) {
      return false;
    }
  }

  return true;
};
export default useSpecialOrder;
