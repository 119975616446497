import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useMemo } from 'react';
import './AnalyticsSalesTable.scss';

interface SalesRecord {
  fridgeCompanyId: number;
  fridgeCompanyName: string;
  year: number;
  month: number;
  main: number;
  deserts: number;
  drinks: number;
  others: number;
  total: number;
  comboDiscount?: number;
}

interface GroupedRecord extends Omit<SalesRecord, 'year' | 'month'> {
  formattedMonth: string;
  key: string;
}

interface AnalyticsSalesTableProps {
  table1: SalesRecord[];
  table2: SalesRecord[];
  table1Loading: boolean;
  table2Loading: boolean;
}

// Utility to format data and calculate grand totals
const formatData = (data: SalesRecord[]): GroupedRecord[] => {
  return data.map(item => {
    const {
      fridgeCompanyId,
      fridgeCompanyName,
      year,
      month,
      main,
      deserts,
      drinks,
      others,
      total,
      comboDiscount,
    } = item;
    const formattedMonth = new Date(year, month - 1).toLocaleString('fr', {
      month: 'long',
      year: 'numeric',
    });

    return {
      key: `${fridgeCompanyId}-${month}`, // Add a unique key for each record
      fridgeCompanyId,
      fridgeCompanyName,
      formattedMonth,
      main,
      deserts,
      drinks,
      others,
      total,
      comboDiscount,
    };
  });
};

const formatNumber = (num: number) => {
  if (Number.isInteger(num)) return num; // No decimal points needed for integers
  return Number(num.toFixed(2)); // Rounds to 2 decimals if more than 2 are present
};

const calculateGrandTotal = (data: GroupedRecord[]) => {
  return data.reduce(
    (acc, record) => ({
      main: acc.main + record.main,
      drinks: acc.drinks + record.drinks,
      deserts: acc.deserts + record.deserts,
      others: acc.others + record.others,
      total: acc.total + record.total,
      comboDiscount: acc.comboDiscount + (record.comboDiscount || 0),
    }),
    { main: 0, drinks: 0, deserts: 0, others: 0, total: 0, comboDiscount: 0 },
  );
};

// Generic Sales Table Component
interface SalesTableProps {
  data: GroupedRecord[];
  displayAsPercentage?: boolean;
  showComboDiscount?: boolean;
  tableClass?: string;
  loading: boolean;
}

const SalesTable: React.FC<SalesTableProps> = ({
  data,
  displayAsPercentage = false,
  showComboDiscount = false,
  tableClass,
  loading = false,
}) => {
  const columns: ColumnsType<GroupedRecord> = [
    {
      title: 'Fridge',
      dataIndex: 'fridgeCompanyName',
      key: 'fridgeCompanyName',
      render: text => <strong>{text}</strong>,
    },
    {
      title: displayAsPercentage ? 'Unités %' : 'Unités',
      dataIndex: 'formattedMonth',
      key: 'formattedMonth',
    },
    {
      title: 'Repas',
      dataIndex: 'main',
      key: 'main',
      render: (_, record) =>
        displayAsPercentage
          ? `${formatNumber((record.main / record.total) * 100)}%`
          : formatNumber(record.main),
    },
    {
      title: 'Boissons',
      dataIndex: 'drinks',
      key: 'drinks',
      render: (_, record) =>
        displayAsPercentage
          ? `${formatNumber((record.drinks / record.total) * 100)}%`
          : formatNumber(record.drinks),
    },
    {
      title: 'Desserts',
      dataIndex: 'deserts',
      key: 'deserts',
      render: (_, record) =>
        displayAsPercentage
          ? `${formatNumber((record.deserts / record.total) * 100)}%`
          : formatNumber(record.deserts),
    },
    {
      title: 'Autres',
      dataIndex: 'others',
      key: 'others',
      render: (_, record) =>
        displayAsPercentage
          ? `${formatNumber((record.others / record.total) * 100)}%`
          : formatNumber(record.others),
    },

    ...(showComboDiscount
      ? [
          {
            title: 'Combo Discount',
            dataIndex: 'comboDiscount',
            key: 'comboDiscount',
            render: (value: any) =>
              value !== undefined ? formatNumber(value) : 'N/A',
          },
        ]
      : []),
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (_, record) =>
        displayAsPercentage ? '100%' : formatNumber(record.total),
    },
  ];

  const grandTotals = calculateGrandTotal(data);

  return (
    <Table
      loading={loading}
      className={tableClass}
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      summary={() => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>Grand Total</Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            {displayAsPercentage
              ? `${formatNumber((grandTotals.main / grandTotals.total) * 100)}%`
              : formatNumber(grandTotals.main)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            {displayAsPercentage
              ? `${formatNumber((grandTotals.drinks / grandTotals.total) * 100)}%`
              : formatNumber(grandTotals.drinks)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}>
            {displayAsPercentage
              ? `${formatNumber((grandTotals.deserts / grandTotals.total) * 100)}%`
              : formatNumber(grandTotals.deserts)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5}>
            {displayAsPercentage
              ? `${formatNumber((grandTotals.others / grandTotals.total) * 100)}%`
              : formatNumber(grandTotals.others)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6}>
            {displayAsPercentage ? '100%' : formatNumber(grandTotals.total)}
          </Table.Summary.Cell>
          {showComboDiscount && (
            <Table.Summary.Cell index={7}>
              {formatNumber(grandTotals.comboDiscount)}
            </Table.Summary.Cell>
          )}
        </Table.Summary.Row>
      )}
    />
  );
};

// Main Sales Data Table Component
const AnalyticsSalesTable: React.FC<AnalyticsSalesTableProps> = ({
  table1,
  table2,
  table1Loading,
  table2Loading,
}) => {
  const formattedDataTable1 = useMemo(() => formatData(table1), [table1]);
  const formattedDataTable2 = useMemo(() => formatData(table2), [table2]);

  return (
    <div className='sales-data-table'>
      {/* Table for Actual Numbers */}
      <SalesTable
        data={formattedDataTable1}
        displayAsPercentage={false}
        tableClass='table-blue'
        loading={table1Loading}
      />

      {/* Table for Percentages */}
      <SalesTable
        data={formattedDataTable1}
        displayAsPercentage={true}
        tableClass='table-yellow'
        loading={table1Loading}
      />

      {/* Table with Combo Discount */}
      <SalesTable
        data={formattedDataTable2}
        displayAsPercentage={false}
        showComboDiscount={true}
        tableClass='table-green'
        loading={table2Loading}
      />
    </div>
  );
};

export default AnalyticsSalesTable;
