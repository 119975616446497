import { CloseOutlined } from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { Button, Form, Input, Layout, Row } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import showEyeIcon from '../../assets//icons/show.svg';
import emailIcon from '../../assets/icons/email.svg';
import hatsvg from '../../assets/icons/hat.svg';
import hideEyeIcon from '../../assets/icons/hide.svg';
import {
  useGlobalActions,
  useGlobalContext,
} from '../../global-context/GlobalContext';
import './Login.scss';

console.log(emailIcon, showEyeIcon, hideEyeIcon);

interface ILogin {
  closePopup: () => void;
}

const Login: React.FC<ILogin> = ({ closePopup }) => {
  const { loginCta } = useGlobalActions();
  const { t } = useTranslate();
  const signInLoading = useGlobalContext(c => c.auth.signInLoading);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const onFinish = (values: any) => {
    loginCta(values.username, 'CUSTOMER_WEB_PORTAL', values.password);
  };
  return (
    <Layout className={classNames('login-screen-container')}>
      <Row justify='space-between'>
        <img className='login-hat' src={hatsvg}></img>
        <Button
          type='text'
          onClick={() => {
            closePopup();
          }}
          icon={<CloseOutlined />}
        ></Button>
      </Row>
      <div className={classNames('login-section')}>
        <h1 className={classNames('login-section-heading')}>
          {t('welcome-discover')}
        </h1>
        <Form
          name='login'
          onFinish={onFinish}
          autoComplete='off'
          layout='vertical'
        >
          {/* Username Input */}
          <Form.Item
            name='username'
            validateTrigger='onSubmit'
            className='form-item'
            rules={[
              {
                required: true,
                message: t('required-field'),
              },
            ]}
          >
            <Input
              styles={{ input: { textAlign: 'center' } }}
              placeholder='Enter your email'
              className='custom-input'
              suffix={<img src={emailIcon} alt="email" className="input-icon" />}
            />
          </Form.Item>

          {/* Password Input */}
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: t('required-field'),
              },
            ]}
            className='form-item'
          >
            <Input
              styles={{ input: { textAlign: 'center' } }}
              placeholder='Enter your password'
              className='custom-input'
              type={passwordVisible ? 'text' : 'password'}
              suffix={
                <img 
                  src={passwordVisible ? showEyeIcon:hideEyeIcon  } 
                  alt="toggle password" 
                  className="input-icon clickable"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                />
              }
            />
          </Form.Item>

          {/* Login Button */}
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={signInLoading}
              block
              style={{
                backgroundColor: '#275747',
                borderRadius: '30px',
                padding: '24px 0',
              }}
            >
              {!signInLoading ? t('sign-in') : ''}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Login;
