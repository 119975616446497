import { gql, useLazyQuery } from '@apollo/client';

const GET_SETTINGS = gql`
  query GetSettings {
    getSettings(
      getSettingsArg: {
        settingsKey: [COMPANY_SPECIAL_ORDER_STANDARD_DELIVERY_CHARGE]
      }
    ) {
      message
      status
      statusCode
      error
      data {
        settingId
        settingKey
        settingValue
      }
    }
  }
`;

const useGetSettings = () => {
  const [getSettings, { error, loading, data }] = useLazyQuery(GET_SETTINGS);

  return {
    getSettings,
    error,
    data: data?.getSettings?.data,
    loading,
  };
};

export default useGetSettings;
