import { useTranslate } from '@tolgee/react';
import VoucherSection from '../../components/voucher/voucher-section/VoucherSection';
import './Voucher.scss';
const Voucher = () => {
  const { t } = useTranslate();

  return (
    <div className='voucher-container'>
      <div className='voucher-header'>
        <h1 className='voucher-header-heading'>{t('voucher-heading-1')}</h1>
        <h1 className='voucher-header-heading'>{t('voucher-heading-2')}</h1>
        <p className='voucher-header-subheading'>{t('voucher-subHeading-1')}</p>
      </div>
      <div className='voucher-section'>
        <VoucherSection />
      </div>
    </div>
  );
};

export default Voucher;
