import { ReactNode, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loader from '../components/common/loader/Loader';
import { PrivateRoute } from '../components/common/ProtectedRoute';
import { PublicOnlyRoute } from '../components/common/PublicOnlyRoute';
import { LINKS } from './Links';
interface RouteConfig {
  path: string;
  element: ReactNode;
  protected?: boolean;
}

export const AppRouter = ({ routes }: { routes: RouteConfig[] }) => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {routes.map(({ path, element, protected: isProtected }, index) => {
          if (isProtected) {
            return (
              <Route
                key={index}
                path={path}
                element={<PrivateRoute>{element}</PrivateRoute>}
              />
            );
          } else if (isProtected == false) {
            return (
              <Route
                key={index}
                path={path}
                element={<PublicOnlyRoute>{element}</PublicOnlyRoute>}
              />
            );
          } else {
            return <Route key={index} path={path} element={element} />;
          }
        })}

        <Route
          path='*'
          element={<Navigate to={LINKS.LOGIN.build()}></Navigate>}
        />
      </Routes>
    </Suspense>
  );
};
