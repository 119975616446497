import { CloseOutlined } from '@ant-design/icons';
import { T, useTranslate } from '@tolgee/react';
import { Button, Form, InputNumber } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import calendarIcon from '../../../assets/icons/calendar.svg';
import { displayCenter } from '../../../utils/styleobjects';
import { CustomDatePicker } from '../../calendar/add-event-form/AddEventForm';
import './OrderRequestForm.scss';

export const OrderRequestForm: React.FC<{
  fromDate: Date;

  loading: boolean;
  closeForm: () => any;
  onFormSubmit: (formValues: any) => any;
}> = ({ fromDate, onFormSubmit, closeForm, loading }) => {
  const [formDate, setFromToDates] = useState<Date>(fromDate);
  const { t } = useTranslate();
  const onFinish = (values: any) => {
    console.log(values);
    onFormSubmit({
      bookingDate: formDate,
      numberOfPeers: values?.numberOfPeers,
      comments: values?.comments,
    });
  };
  return (
    <div className='form-container'>
      <Form layout='vertical' onFinish={onFinish}>
        <div className='closeicon'>
          <CloseOutlined onClick={() => closeForm()} />
        </div>
        <div className='title'>
          <img className='title-img' src={calendarIcon} />

          <span>
            <T keyName='tasting-heading2' />
          </span>
        </div>
        <Form.Item name='date' style={{ marginBottom: '1.4rem' }}>
          <CustomDatePicker
            placeholder=''
            defaultDate={formDate}
            onDateChange={(newDate: Date) => {
              setFromToDates(newDate);
            }}
          />
        </Form.Item>

        {/* Number of People */}

        <Form.Item
          name='numberOfPeers'
          label={t('number-people')}
          rules={[
            { required: true, message: 'Please provide the number of people!' },
          ]}
          style={{
            marginBottom: '1.4rem',
            position: 'relative',
          }}
        >
          <InputNumber min={1} max={100} className='input-number' />
        </Form.Item>

        {/* Comments */}
        <Form.Item
          label='Comments'
          name='comments'
          style={{ marginBottom: '1.8rem' }}
        >
          <TextArea rows={3} style={{ lineHeight: '1.5' }} placeholder='' />
        </Form.Item>

        <Form.Item style={{ ...displayCenter }}>
          <Button
            className='submit-btn'
            type='primary'
            htmlType='submit'
            loading={loading}
            block
          >
            {loading ? '' : 'Submit'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
