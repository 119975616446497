import { useTolgee } from '@tolgee/react';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import useGetOrderPreferences from '../../../hooks/graphql/useGetOrderPrefernces';
import useGetSettings from '../../../hooks/graphql/useGetSettings';
import { useGetSpecialOrderProducts } from '../../../hooks/graphql/useGetSpecialOrderProducts';
import { useEffectOnce } from '../../../hooks/utils/useEffectOnce';
import { useSelection } from '../../../hooks/utils/useSelection';
import { isoStringToLocalDate } from '../../../utils/calendarUtility';
import { toggleItemInArray } from '../../../utils/commonUtility';
import CheckoutPage from '../checkout-page/CheckoutPage';
import FoodCard from '../food-card/FoodCard';
import OrderPreferences from '../order-preferences/OrderPreferences';
import SpecialOrderHeader from '../special-order-header/SpecialOrderHeader';
import SpecialOrderMealSelector from '../special-order-meal-selector/SpecialOrderMealSelector';
import './SpecialOrderSection.scss';

const SpecialOrderSection: React.FC = () => {
  const { getSpecialOrderProducts, specialOrderProducts, loading } =
    useGetSpecialOrderProducts();
  const { getSettings, data } = useGetSettings();
  const [orderSectionVisible, toggleOrderSectionVisibility] = useState(true);
  const { getOrderPreferences, orderPreferences } = useGetOrderPreferences();
  const { getLanguage } = useTolgee();

  // State management using hooks
  const [selectedProductType, setSelectedProductType] =
    useState<string>('Main');
  const [selectedDate, setSelectedDate] = useState<{
    date: string;
    day: string;
  } | null>(null);

  // Use custom hooks for meal type and allergies selection
  const mealTypeSelection = useSelection<string>([]);
  const allergySelection = useSelection<number>([]);

  // Fetch order preferences once
  useEffectOnce(() => {
    getOrderPreferences();
    getSettings();
  });

  // Sync meal types with order preferences
  useEffect(() => {
    if (orderPreferences.length > 0) {
      const preferenceNames = orderPreferences.map(
        (preference: any) => preference.titleEn,
      );
      mealTypeSelection.setSelectedItems(preferenceNames);
    }
  }, [orderPreferences]);

  // Centralized fetch function that uses the latest values
  const fetchProducts = ({
    date,
    day,
    allergies,
  }: {
    date: string;
    day: string;
    productType: string;
    allergies: number[];
  }) => {
    getSpecialOrderProducts({
      variables: {
        weekDate: isoStringToLocalDate(date),
        day: day,
        allergyIds: allergies,
      },
      fetchPolicy: 'no-cache',
    });
  };

  // Handlers with explicit current state
  const handleDateSelect = (dateObj: { date: string; day: string }) => {
    setSelectedDate(dateObj);
    fetchProducts({
      date: dateObj.date,
      day: dateObj.day,
      productType: selectedProductType,
      allergies: allergySelection.selectedItems,
    });
  };

  const handleProductTypeSelect = (productType: string) => {
    setSelectedProductType(productType);
  };

  const handleAllergiesChange = (allergyId: number) => {
    const updatedAllergies = toggleItemInArray(
      allergySelection.selectedItems,
      allergyId,
    );
    allergySelection.setSelectedItems(updatedAllergies);
    if (selectedDate) {
      fetchProducts({
        date: selectedDate.date,
        day: selectedDate.day,
        productType: selectedProductType,
        allergies: updatedAllergies,
      });
    }
  };

  const handleMealTypeSelect = (mealType: string) => {
    mealTypeSelection.toggleSelection(mealType);
  };

  // Filter products based on selected meal types
  const filteredProducts = useMemo(
    () =>
      specialOrderProducts.filter(
        (food: any) =>
          mealTypeSelection.selectedItems.includes(food.mealType) &&
          food.productType === selectedProductType,
      ),
    [
      specialOrderProducts,
      mealTypeSelection.selectedItems,
      selectedProductType,
    ],
  );

  return (
    <div className='special-order-section-container'>
      {/* Header Section */}
      <SpecialOrderHeader
        onDateSelect={handleDateSelect}
        selectedDate={selectedDate}
        orderSectionVisible={orderSectionVisible}
        toggleOrderSectionVisibility={toggleOrderSectionVisibility}
      />
      {orderSectionVisible ? (
        <>
          {/* Meal Type and Allergies Selector */}
          <SpecialOrderMealSelector
            onSelect={handleProductTypeSelect}
            onAllergiesSelect={handleAllergiesChange}
            selectedAllergies={allergySelection.selectedItems}
          />

          {/* Order Preferences Section */}
          <OrderPreferences
            orderPreferences={orderPreferences}
            language={getLanguage() as any}
            onMealTypeSelect={handleMealTypeSelect}
            selectedMealType={mealTypeSelection.selectedItems}
          />

          {/* Products Grid */}
          <div
            className={classNames('special-order-grid', {
              'no-data': !loading && filteredProducts.length === 0,
            })}
          >
            {!loading ? (
              filteredProducts.length > 0 ? (
                filteredProducts.map((food: any) => (
                  <FoodCard key={food.productId} foodInfo={food} />
                ))
              ) : (
                <div>No dish available</div>
              )
            ) : (
              Array.from({ length: 11 }, (_, index) => <Skeleton key={index} />)
            )}
          </div>
        </>
      ) : (
        <CheckoutPage
          selectedDate={selectedDate}
          deliveryCharges={data?.[0]?.settingValue ?? 0}
        />
      )}
    </div>
  );
};

export default SpecialOrderSection;
