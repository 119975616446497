import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import BonusIcon from '../../../assets/icons/bons.svg';
import ArrowIcon from '../../../assets/icons/white-arrow-right.svg';
import BackgroundImage from '../../../assets/images/Voucher-image.png';
import VoucherCheckout from '../voucher-checkout/VoucherCheckout';
import './VoucherSection.scss';
const VoucherSection = () => {
  const { t } = useTranslate();
  const [checkoutSectionVisible, toggleCheckoutSectionVisibility] =
    useState(false);

  return (
    <>
      {checkoutSectionVisible ? (
        <VoucherCheckout
          toggleCheckoutSectionVisibility={toggleCheckoutSectionVisibility}
        />
      ) : (
        <div className='voucher-main-section-container'>
          <img className='voucher-section-img' src={BackgroundImage} />
          <div className='voucher-main-section-header'>
            <div
              className='voucher-main-section-header-container'
              onClick={() => toggleCheckoutSectionVisibility(true)}
            >
              <img src={BonusIcon} alt='' srcSet='' />
              <p>commander vos bons ici</p>
              <img src={ArrowIcon} alt='' srcSet='' />
            </div>
          </div>
          <div className='voucher-section-detail'>
            <h2 className='voucher-section-primary__text'>
              {t('voucher-section-heading-1')}
            </h2>
            <div className='voucher-section-secondary__container'>
              <h1 className='voucher-section-secondary__text'>
                {t('voucher-section-heading-2')}
              </h1>
            </div>

            <div className='voucher-section-tertiary__container'>
              <h1 className='voucher-section-tertiary__text'>
                {t('voucher-section-heading-3')}
              </h1>
            </div>
          </div>
          <div className='voucher-section-how-it-works'>
            <h2>Comment</h2>
            <h3>ça marche ?</h3>
            <ol>
              <li>
                Personnalisez votre message (optionnel), pour rendre ce geste
                encore plus spécial.
              </li>
              <li>Validez et payez votre commande.</li>
              <li>Vous recevrez une confirmation par email.</li>
            </ol>
          </div>
        </div>
      )}
    </>
  );
};

export default VoucherSection;
