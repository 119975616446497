import { gql, useMutation } from '@apollo/client';
import { useGlobalContext } from '../../global-context/GlobalContext';
import { toLocalDate } from '../../utils/calendarUtility';

const ADD_STAFFING_STATUS = gql`
  mutation AddCompanyStaffingStatus(
    $status: String!
    $reason: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    addCompanyStaffingStatus(
      companyStaffingStatusesArgs: {
        status: $status
        reason: $reason
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      message
      status
      statusCode
      error
      data {
        isCreated
      }
    }
  }
`;

export const useAddStaffingStatus = () => {
  const [addStaffingStatus, { error, data, loading }] =
    useMutation(ADD_STAFFING_STATUS);

  const companyId = useGlobalContext(c => c.auth.companyInfo?.companyId);

  const addStatus = (
    status: string,
    reason: string,
    startDate: Date,
    endDate: Date,
    onCompleteCb?: (data: any) => any,
  ) => {
    addStaffingStatus({
      variables: {
        status: status,
        reason: reason,
        startDate: toLocalDate(startDate),
        endDate: toLocalDate(endDate),
      },
      onCompleted: onCompleteCb,
    });
  };

  return { addStatus, error, data, loading };
};
