import { Card, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CalendarCommon } from '../../common/calendar-common/CalendarCommon';
import DrawerCommon from '../../common/drawer-common/DrawerCommon';

import './RequestsCalendarOutline.scss';

import { ICalendarCommon, ISelectedDays } from '../../../utils/calendarUtility';

import { useGlobalContext } from '../../../global-context/GlobalContext';
import useIsMobile from '../../../hooks/utils/useIsMobile';
import { useTastingService } from '../../../hooks/utils/useTastingService';
import { openConfirmationPopup } from '../../../utils/commonUtility';
import { displayCenter } from '../../../utils/styleobjects';
import { CalendarMobile } from '../../common/calendar-mobile/CalendarMobile';
import {
  ConfirmationModal,
  ReservationCancelled,
  ReservedSuccess,
} from '../confirmation-modal/ConfirmationModal';
import { OrderRequestForm } from '../order-request-form/OrderRequestForm';
import { RequestsRemarks } from '../request-remarks/RequestsRemarks';
import { TastingIcon } from '../requests-header/RequestsHeader';
export enum TastingStatuses {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
}

export const tastingStutusesData: any = {
  [TastingStatuses.PENDING]: {
    color: '#fcb566',
    Icon: TastingIcon,
  },
  [TastingStatuses.CONFIRMED]: {
    color: '#dce7bd',
    Icon: TastingIcon,
  },
  [TastingStatuses.CANCELED]: {
    color: '#e06b56',
    Icon: TastingIcon,
  },
};

export const CalendarOutline: React.FC = () => {
  const isMobile = useIsMobile();
  const isRemarksOpen =
    useGlobalContext(c => c.app.openeRemarksdPanelType) == 'EVENTS';
  const {
    getBookingsBtwDates,
    tastingData,
    refetchGetBookingsBtwDates,
    bookTasting,
    bookTastingLoading,
    cancelTasting,
    disabledDates,
  } = useTastingService();
  const [addEventFormOpen, showAddEventForm] = useState(false);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [fromToDates, setFromToDates] = useState<[Date, Date] | undefined>(
    undefined,
  );
  const [confirmationProps, setConfirmationProps] = useState<{
    isOpen: boolean;
    date?: Date;
  }>({
    isOpen: false,
    date: undefined,
  });
  const [selectedStaffingDay, setStaffingDay] = useState<
    ISelectedDays | undefined
  >(undefined);
  const setShowAddEventForm = (bool: boolean) => {
    setStaffingDay(undefined);
    showAddEventForm(bool);
  };

  const onDeleteClick = (selectedEvent: ISelectedDays) => {
    openConfirmationPopup({
      title: 'Do you really want to delete this event?',
      onOkCB: () => {
        cancelTasting(selectedEvent.id, data => {
          if (data?.cancelTastingReservation?.data?.isCancelled) {
            refetchGetBookingsBtwDates();
            setConfirmationProps({ isOpen: true });
          }
        });
      },
    });
  };
  const onSubmitForm = (values: any) => {
    const { bookingDate, numberOfPeers, comments } = values;
    bookTasting(bookingDate, comments, numberOfPeers, data => {
      if (data?.bookTasting?.data?.isCreated) {
        setShowAddEventForm(false);
        refetchGetBookingsBtwDates();
        setConfirmationProps({ isOpen: true, date: bookingDate });
      }
    });
  };
  useEffect(() => {
    if (isMobile) {
      const now = new Date();
      getBookingsBtwDates(
        new Date(now.getFullYear(), now.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth() + 1, 0),
      );
    } else {
      getBookingsBtwDates(
        new Date(year, 0, 1),
        new Date(year, 11, 31, 23, 59, 59, 999),
      );
    }
  }, [year]);

  return (
    <Row
      justify='center'
      style={{ flexWrap: 'unset', height: '100%', textAlign: 'start' }}
    >
      <CalendarWrapper
        onDateClickHandler={date => {
          showAddEventForm(true);
          setFromToDates([date, date]);
        }}
        onDateDraggedHandler={dates => {
          showAddEventForm(true);
          setFromToDates([dates[0], dates[1]]);
        }}
        onDelete={onDeleteClick}
        selectedDays={tastingData ?? []}
        onYearChange={(year: number) => {
          setYear(year);
        }}
        onMonthChange={(year: number, month: number) => {
          getBookingsBtwDates(
            new Date(year, month, 1),
            new Date(year, month + 1, 0, 23, 59, 59, 999),
          );
        }}
        disabledDates={disabledDates}
        year={year}
      />
      <AddEditDetailsDrawer
        openDrawer={addEventFormOpen}
        setDrawerOpen={bool => setShowAddEventForm(bool)}
        onSubmitForm={values => onSubmitForm(values)}
        loading={bookTastingLoading}
        selectedStaffingDay={selectedStaffingDay}
        fromToDates={fromToDates}
      />
      <ConfirmationModal
        {...confirmationProps}
        setOpen={(bool: boolean) => {
          setConfirmationProps({ isOpen: bool });
        }}
      >
        {confirmationProps?.date ? (
          <ReservedSuccess date={confirmationProps.date} />
        ) : (
          <ReservationCancelled />
        )}
      </ConfirmationModal>
      {isRemarksOpen && (
        <RequestsRemarks refetchMainCalendarData={refetchGetBookingsBtwDates} />
      )}
    </Row>
  );
};

const AddEditDetailsDrawer = ({
  openDrawer,
  setDrawerOpen,
  fromToDates,
  selectedStaffingDay,
  onSubmitForm,
  loading,
}: {
  openDrawer: boolean;
  setDrawerOpen: (bool: boolean) => any;
  onSubmitForm: (bool: boolean) => any;
  fromToDates: Date[] | undefined;
  selectedStaffingDay: ISelectedDays | undefined;
  loading: boolean;
}) => {
  const isMobile = useIsMobile();

  return (
    <DrawerCommon
      isVisible={openDrawer}
      setVisibility={setDrawerOpen}
      placement='right'
      styles={{ width: isMobile ? '100%' : '25%' }}
    >
      {fromToDates && openDrawer && (
        <OrderRequestForm
          fromDate={fromToDates[0]}
          loading={loading}
          closeForm={() => {
            setDrawerOpen(false);
          }}
          onFormSubmit={values => {
            onSubmitForm(values);
          }}
        />
      )}
    </DrawerCommon>
  );
};

const CalendarWrapper: React.FC<ICalendarCommon> = ({ ...props }) => {
  const isMobile = useIsMobile();

  return (
    <div
      className='calendar-container'
      style={{ minHeight: !isMobile ? '100%' : '' }}
    >
      {isMobile ? (
        <CalendarMobile {...props} />
      ) : (
        <Card
          bordered={false}
          styles={{
            body: {
              width: '100%',
              ...displayCenter,
              height: '100%',
            },
          }}
          className='calendar-card'
        >
          <CalendarCommon {...props} />
        </Card>
      )}
    </div>
  );
};
