import { gql, useLazyQuery } from '@apollo/client';
// @typescript-eslint/no-unused-vars
// TypeScript type for DatePeriod
type DatePeriod = {
  startDate: string;
  endDate: string;
};

// TypeScript type for Pagination
type Pagination = {
  page: number;
  limit: number;
};

// TypeScript type for Sort
type Sort = {
  name: string; // Field by which to sort, e.g., "startDate", "endDate"
  order: 'ASC' | 'DESC'; // Sorting order: Ascending or Descending
};

const STAFFING_STATUS = gql`
  query companyStaffingStatuses(
    $filter: JSON
    $pagination: Pagination
    $sort: Sort
  ) {
    companyStaffingStatuses(
      companyStaffingStatusesArgs: {
        filter: $filter
        pagination: $pagination
        sort: $sort
      }
    ) {
      message
      status
      statusCode
      error
      data {
        reportType
        base64String
        count
        page
        pageSize
        list {
          id
          companyId
          status
          reason
          startDate
          endDate
          createdAt
          modifiedAt
          createdByResourceType
          createdByResourceId
          createdByResourceEmail
        }
      }
    }
  }
`;

export const useGetStaffingStatus = () => {
  const [getCompanyStatuses, { error, data, loading }] = useLazyQuery(
    STAFFING_STATUS,
    { fetchPolicy: 'no-cache' },
  );

  return {
    getCompanyStatuses,
    error,
    data,
    loading,
  };
};
