import { Card, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CalendarCommon } from '../../common/calendar-common/CalendarCommon';
import DrawerCommon from '../../common/drawer-common/DrawerCommon';
import { AddEventForm } from '../add-event-form/AddEventForm';
import './CalendarOutline.scss';

import { ICalendarCommon, ISelectedDays } from '../../../utils/calendarUtility';

import { useGlobalContext } from '../../../global-context/GlobalContext';
import useIsMobile from '../../../hooks/utils/useIsMobile';
import { useStaffingService } from '../../../hooks/utils/useStaffingService';
import { openConfirmationPopup, showToast } from '../../../utils/commonUtility';
import { displayCenter } from '../../../utils/styleobjects';
import { CalendarMobile } from '../../common/calendar-mobile/CalendarMobile';
import {
  ClosedIcon,
  HolidaysIcon,
  PHolidaysIcon,
  ReductionIcon,
} from '../../common/create-icon/CreateIcon';
import { CalendarRemarks } from '../calendar-remarks/CalendarRemarks';
enum StaffingStatuses {
  CLOSE = 'Close',
  REDUCTION = 'Reduction of workforce',
  PUBLICHOLIDAY = 'Public holidays',
  VACATION = 'Vacation',
}

export const staffingStatusesData: any = {
  [StaffingStatuses.VACATION]: {
    color: '#e0aa67',
    Icon: PHolidaysIcon,
  },
  [StaffingStatuses.REDUCTION]: {
    color: '#c9dace',
    Icon: ReductionIcon,
  },
  [StaffingStatuses.CLOSE]: {
    color: '#bd8167',
    Icon: ClosedIcon,
  },
  [StaffingStatuses.PUBLICHOLIDAY]: {
    color: '#adb08e',
    Icon: HolidaysIcon,
  },
};

export const CalendarOutline: React.FC = () => {
  const isRemarksOpen =
    useGlobalContext(c => c.app.openeRemarksdPanelType) == 'CALENDAR';
  const {
    getStatusBtwDates,
    refetchGetStatusBtwDates,

    addStatus,

    updateStatus,
    deleteStatus,

    calendarData,
    addStatusLoading,
    updateStatusLoading,
  } = useStaffingService();
  const isMobile = useIsMobile();
  const [addEventFormOpen, showAddEventForm] = useState(false);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [fromToDates, setFromToDates] = useState<[Date, Date] | undefined>(
    undefined,
  );
  const [selectedStaffingDay, setStaffingDay] = useState<
    ISelectedDays | undefined
  >(undefined);
  const setShowAddEventForm = (bool: boolean) => {
    setStaffingDay(undefined);
    showAddEventForm(bool);
  };
  const onEditClick = (selectedEvent: ISelectedDays) => {
    showAddEventForm(true);
    setFromToDates([selectedEvent.startdate, selectedEvent.lastdate]);
    setStaffingDay(selectedEvent);
  };

  const onDeleteClick = (selectedEvent: ISelectedDays) => {
    openConfirmationPopup({
      title: 'Do you really want to delete this event?',

      onOkCB: () => {
        deleteStatus(selectedEvent.id, data => {
          if (data?.deleteCompanyStaffingStatus?.data?.isDeleted) {
            showToast('event-deleted', 'success', 'event-deleted-desc');
            refetchGetStatusBtwDates();
          }
        });
      },
    });
  };
  const onSubmitForm = (values: any) => {
    const { status, comments, startDate, endDate } = values;
    if (startDate > endDate) {
      showToast('error_startdate', 'error');
      return;
    }
    if (selectedStaffingDay) {
      updateStatus(
        selectedStaffingDay.id,
        status,
        comments,
        startDate,
        endDate,
        data => {
          if (data?.updateCompanyStaffingStatus?.data?.isUpdate) {
            setShowAddEventForm(false);
            refetchGetStatusBtwDates();
            showToast('event-updated', 'success', 'event-updated-desc');
          }
        },
      );
    } else {
      addStatus(status, comments, startDate, endDate, data => {
        if (data?.addCompanyStaffingStatus?.data?.isCreated) {
          refetchGetStatusBtwDates();

          setShowAddEventForm(false);
          showToast('event-reserved', 'success', 'event-reserved-desc');
        }
      });
    }
  };
  useEffect(() => {
    if (isMobile) {
      const now = new Date();
      getStatusBtwDates(
        new Date(now.getFullYear(), now.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth() + 1, 0),
      );
    } else {
      getStatusBtwDates(
        new Date(year, 0, 1),
        new Date(year, 11, 31, 23, 59, 59, 999),
      );
    }
  }, [year]);

  return (
    <Row justify='center' style={{ flexWrap: 'unset', height: '100%' }}>
      <CalendarWrapper
        onDateClickHandler={date => {
          showAddEventForm(true);
          setFromToDates([date, date]);
        }}
        onDateDraggedHandler={dates => {
          showAddEventForm(true);
          setFromToDates([dates[0], dates[1]]);
        }}
        onDelete={onDeleteClick}
        onEdit={onEditClick}
        selectedDays={calendarData ?? []}
        onYearChange={(year: number) => {
          setYear(year);
        }}
        onMonthChange={(year: number, month: number) => {
          getStatusBtwDates(
            new Date(year, month, 1),
            new Date(year, month + 1, 0, 23, 59, 59, 999),
          );
        }}
        year={year}
      />
      <AddEditDetailsDrawer
        openDrawer={addEventFormOpen}
        setDrawerOpen={bool => setShowAddEventForm(bool)}
        onSubmitForm={values => onSubmitForm(values)}
        loading={addStatusLoading || updateStatusLoading}
        selectedStaffingDay={selectedStaffingDay}
        fromToDates={fromToDates}
      />
      {isRemarksOpen && (
        <CalendarRemarks refetchMainCalendarData={refetchGetStatusBtwDates} />
      )}
    </Row>
  );
};

const AddEditDetailsDrawer = ({
  openDrawer,
  setDrawerOpen,
  fromToDates,
  selectedStaffingDay,
  onSubmitForm,
  loading,
}: {
  openDrawer: boolean;
  setDrawerOpen: (bool: boolean) => any;
  onSubmitForm: (bool: boolean) => any;
  fromToDates: Date[] | undefined;
  selectedStaffingDay: ISelectedDays | undefined;
  loading: boolean;
}) => {
  const isMobile = useIsMobile();
  return (
    <DrawerCommon
      isVisible={openDrawer}
      setVisibility={setDrawerOpen}
      placement='right'
      styles={{ width: isMobile ? '100%' : '25%' }}
    >
      {fromToDates && openDrawer && (
        <AddEventForm
          fromDate={fromToDates[0]}
          toDate={fromToDates[1]}
          loading={loading}
          selectedStaffingDay={selectedStaffingDay}
          closeForm={() => {
            setDrawerOpen(false);
          }}
          onFormSubmit={values => {
            onSubmitForm(values);
          }}
        />
      )}
    </DrawerCommon>
  );
};

const CalendarWrapper: React.FC<ICalendarCommon> = ({ ...props }) => {
  const isMobile = useIsMobile();

  return (
    <div
      className='calendar-container'
      style={{ minHeight: !isMobile ? '100%' : '' }}
    >
      {isMobile ? (
        <CalendarMobile {...props} />
      ) : (
        <Card
          bordered={false}
          styles={{
            body: {
              width: '100%',
              height: '100%',
              overflow: 'auto',
              ...displayCenter,
            },
          }}
          className='calendar-card'
        >
          <CalendarCommon {...props} />
        </Card>
      )}
    </div>
  );
};
