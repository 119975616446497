import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import './AnalyticsChartSection.scss';

Chart.register(
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  ChartDataLabels,
);

// Define the type for each chart data item
interface ChartDataItem {
  name: string;
  value: number;
}

// Props for AnalyticsChartSection
interface AnalyticsChartSectionProps {
  data: ChartDataItem[];
  chartType?: 'pie' | 'bar'; // Add more chart types here if needed
}

// Define a color scheme
const COLORS: { [key: string]: string } = {
  Fish: '#1DA0B4', // Red color for Fish
  Meat: '#F9341D', // Blue color for Meat
  Poultry: '#FFD452', // Yellow color for Poultry
  Vegan: '#aed65c', // Teal color for Vegan
  Vegetarian: '#55bd4b', // Purple color for Vegetarian
};

// Generate a unique color for unknown categories if needed
const generateUniqueColor = (index: number): string => {
  const colors = ['#9FE785', '#76C199', '#FFE783', '#FF644E', '#99D9FF'];
  return colors[index % colors.length];
};

// Function to get the color for a specific category
const getColorForCategory = (category: string, index: number): string => {
  return COLORS[category] || generateUniqueColor(index);
};

const AnalyticsChartSection: React.FC<AnalyticsChartSectionProps> = ({
  data,
  chartType = 'bar',
}) => {
  // Prepare the chart data for Chart.js
  const chartData = {
    labels: data.map(item => item.name),
    datasets: [
      {
        data: data.map(item => item.value),
        backgroundColor: data.map((item, index) =>
          getColorForCategory(item.name, index),
        ),
        hoverBackgroundColor: data.map((item, index) =>
          getColorForCategory(item.name, index),
        ),
      },
    ],
  };

  const options: any = {
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end' as const,
        clamp: true,
        align: 'top' as const,
        color: 'gray',
        clip: false,
        font: {
          weight: 'bold',
        },
        formatter: (value: number) => value.toFixed(2),
      },
    },
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    layout: {
      padding: {
        top: 20, // Add extra padding to the top to avoid cropping
      },
    },
  };

  return (
    <div className='analytics-chart-section-container'>
      {chartType === 'pie' && (
        <Pie
          className='analytics-chart-section-container__pie'
          data={chartData}
          options={options}
        />
      )}
      {chartType === 'bar' && <Bar data={chartData} options={options} />}
    </div>
  );
};

export default AnalyticsChartSection;
