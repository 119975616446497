import { useTolgee, useTranslate } from '@tolgee/react';
import React, { useState } from 'react';
import Logo from '../../assets/images/Logo.png';
import './Header.scss'; // Import your CSS file for styling

import Login from '../../pages/login/Login';

import { Layout } from 'antd';
import classNames from 'classnames';
import { useGlobalContext } from '../../global-context/GlobalContext';
import { changeLanguageGraphQL } from '../../utils/graphqlClient';
import DrawerCommon from '../common/drawer-common/DrawerCommon';
import { RemarksDrawer } from '../common/remarks-drawer/RemarksDrawer';
type HeaderProps = {
  isSignedIn: boolean; // Prop to check if user is signed in
};
const languages = [
  { code: 'en', label: 'EN' },
  { code: 'fr', label: 'FR' },
  { code: 'de', label: 'DE' },
];
const Header: React.FC<HeaderProps> = ({ isSignedIn }) => {
  const [signUpVisible, setSignUp] = useState(false);
  const { t } = useTranslate();
  const isSidePanelOpen = useGlobalContext(c => c.app.isSidePanelOpen);

  return (
    <Layout.Header className={classNames('header')}>
      <DrawerCommon
        isVisible={!isSignedIn && signUpVisible}
        placement='left'
        setVisibility={setSignUp}
      >
        <Login
          closePopup={() => {
            setSignUp(false);
          }}
        />
      </DrawerCommon>
      <div
        className={classNames('header-container', {
          'sign-in': isSignedIn,
          'sidebar-open': isSidePanelOpen && isSignedIn,
        })}
      >
        <div className='left-section'>
          {isSignedIn ? null : (
            <button
              className='sign-up-button'
              onClick={() => {
                setSignUp(true);
              }}
            >
              {t('log-in')}
            </button>
          )}
        </div>
        <div className='center-section'>
          <img src={Logo} alt='Company Logo' className='logo' />
        </div>
        <div className='right-section'>
          <span className='remarks-drawer'>
            <RemarksDrawer />
          </span>

          <span className='language-selector'>
            <LanguageSelector />
          </span>
        </div>
      </div>
    </Layout.Header>
  );
};

const LanguageSelector: React.FC = () => {
  const { changeLanguage, getLanguage } = useTolgee();
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguage());

  return (
    <div>
      {languages.map(lang => (
        <span
          key={lang.code}
          style={{ marginLeft: '8px' }}
          onClick={() => {
            changeLanguage(lang.code);
            setSelectedLanguage(lang.code);
            changeLanguageGraphQL(lang.code);
          }}
        >
          <span
            className={classNames('language-label', {
              'language-selected': lang.code === selectedLanguage,
            })}
          >
            {lang.label}
          </span>
        </span>
      ))}
    </div>
  );
};

export default Header;
