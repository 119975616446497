import { CalendarOutline } from '../../components/requests/requests-calendar-outline/RequestsCalendarOutline';
import { RequestsHeader } from '../../components/requests/requests-header/RequestsHeader';
import './TestingRequests.scss';
export const TestingRequests = () => {
  return (
    <div className='testing-req-container'>
      <div className='user-list-header'>
        <RequestsHeader />
      </div>

      <div className='user-list-section'>
        <CalendarOutline />
      </div>
    </div>
  );
};
