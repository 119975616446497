import { useTranslate } from '@tolgee/react';
import SpecialOrderSection from '../../components/special-order/special-order-section/SpecialOrderSection';
import './SpecialOrder.scss';
const SpecialOrder = () => {
  const { t } = useTranslate();

  return (
    <div className='special-order-container'>
      <div className='special-order-header'>
        <h1 className='special-order-header-heading'>
          {t('special-order-heading-1')}
        </h1>
        <h1 className='special-order-header-heading'>
          {t('special-order-heading-2')}
        </h1>
        <p className='special-order-header-subheading'>
          {t('special-order-subHeading-1')}
        </p>
        <p className='special-order-header-subheading'>
          {t('special-order-subHeading-2')}
        </p>
      </div>
      <div className='special-order-section'>
        <SpecialOrderSection />
      </div>
    </div>
  );
};

export default SpecialOrder;
