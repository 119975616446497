import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

interface HoverWrapperProps {
  Icon: React.FC<any>;
  onEdit?: () => any;
  onDelete?: () => any;
}

export const HoverWrapper: React.FC<HoverWrapperProps> = ({
  Icon,
  onEdit,
  onDelete,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onClick={() => {
        setHovered(prev => !prev);
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Icon />
      </div>
      {hovered && (onEdit || onDelete) && (
        <div
          style={{
            position: 'absolute',
            left: '101%',
            bottom: '0',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '8px',
            zIndex: '9999',
          }}
          onClick={() => {
            setHovered(false);
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {onEdit && (
            <div
              style={{ padding: '10px', cursor: 'pointer' }}
              onClick={() => {
                onEdit();
              }}
            >
              <EditOutlined />
            </div>
          )}

          <div
            style={{
              padding: onEdit ? '0 10px 10px' : '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onDelete?.();
            }}
          >
            <DeleteOutlined />
          </div>
        </div>
      )}
    </div>
  );
};
