import { Navigate } from 'react-router-dom';
import { useGlobalActions } from '../../global-context/GlobalContext';
import { useEffectOnce } from '../../hooks/utils/useEffectOnce';
import { LINKS } from '../../routes/Links';

export const LogOut = () => {
  const { clearUserSession } = useGlobalActions();
  useEffectOnce(() => {
    clearUserSession();
  });
  return <Navigate to={LINKS.LOGOUT.build()} />;
};
