import { Menu } from 'antd';
import React from 'react';

// Menu Component
const FeelEatMenu: React.FC<any> = ({ children, ...props }) => (
  <Menu {...props}>{children}</Menu>
);

// SubMenu Component (SubMenu has its own props from Menu)
const FeelEatSubMenu: React.FC<any> = ({ children, ...props }) => (
  <Menu.SubMenu {...props}>{children}</Menu.SubMenu>
);

// MenuItem Component (Menu.Item also has specific props from Menu)
const FeelEatMenuItem: React.FC<any> = ({ children, ...props }) => (
  <Menu.Item {...props}>{children}</Menu.Item>
);

export { FeelEatMenu, FeelEatMenuItem, FeelEatSubMenu };
