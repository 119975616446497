import { useTranslate } from '@tolgee/react';
import UserListSection from '../../components/user-list/user-list-section/UserListSection';
import './UserList.scss';
const UserList = () => {
  const { t } = useTranslate();

  return (
    <div className='user-list-container'>
      <div className='user-list-header'>
        <h1 className='user-list-header-heading'>{t('check-your-list')}</h1>
        <h1 className='user-list-header-heading'>
          {t('of-the-registered-employee')}
        </h1>
        <p className='user-list-header-subheading'>
          {t('check-employee-status-msg')}
        </p>
      </div>
      <div className='user-list-section'>
        <UserListSection />
      </div>
    </div>
  );
};

export default UserList;
