import { gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

// @typescript-eslint/no-unused-vars
// TypeScript type for DatePeriod
type DatePeriod = {
  startDate: string;
  endDate: string;
};

// TypeScript type for Pagination
type Pagination = {
  page: number;
  limit: number;
};

// TypeScript type for Sort
type Sort = {
  name: string; // Field by which to sort, e.g., "startDate", "endDate"
  order: 'ASC' | 'DESC'; // Sorting order: Ascending or Descending
};

interface UseGetCompanySalesMetric {
  metric: number;
}

interface SalesData {
  list: any[]; // Define this according to the shape of your data
}

const GET_COMPANY_SALES_BY_METRIC = gql`
  query getCompanySalesByMetric(
    $metric: Float!
    $filter: JSON
    $pagination: Pagination
    $sort: Sort
  ) {
    getCompanySalesByMetric(
      getCompanySalesArgs: {
        metric: $metric
        filter: $filter
        pagination: $pagination
        sort: $sort
      }
    ) {
      message
      status
      statusCode
      error
      data {
        count
        page
        pageSize
        list {
          name
          value
        }
        metric
        reportType
        base64String
      }
    }
  }
`;

export const useGetCompanySalesMetric = ({
  metric,
}: UseGetCompanySalesMetric) => {
  const [getCompanySalesMetric, { error, data, loading }] = useLazyQuery(
    GET_COMPANY_SALES_BY_METRIC,
  );

  const fetchMetricData = useCallback(
    (variables: any) => {
      getCompanySalesMetric({ variables: { metric, ...variables } });
    },
    [getCompanySalesMetric],
  );

  return {
    fetchMetricData,
    error,
    salesData: data?.getCompanySalesByMetric?.data as SalesData,
    loading,
  };
};
