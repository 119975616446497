import { DownOutlined } from '@ant-design/icons';
import { Badge, Col, Row, Select, Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import {
  areDatesSameIgnoringTime,
  createDateKey,
  createNewDate,
  getDateFromDateKey,
  getDifferenceBetweenDates,
  getMonthAndDayFromDateKey,
  ICalendarCommon,
  IRenderDateCells,
  isDateInPast,
  isValidDate,
  months,
} from '../../../utils/calendarUtility';
import { HoverWrapper } from '../hover-wrapper/HoverWrapper';
import './CalendarCommon.scss';

export const CalendarCommon: React.FC<ICalendarCommon> = ({
  selectedDays,
  year,
  onYearChange,
  onDateClickHandler,
  onDateDraggedHandler,
  onEdit,
  onDelete,
  disabledDates = [],
}) => {
  const isDragging = React.useRef(false);
  const lastDay = React.useRef<null | number[]>(null);
  const dragState = React.useRef<string[]>([]);
  const [selectedDates, setSelectedDates] = React.useState<string[] | null>([]);

  const handleMouseDown = useCallback((day: number, month: number) => {
    isDragging.current = true;
    const date = createDateKey(day, month);
    lastDay.current = [Number(day), Number(month)];
    dragState.current = [date];
    setSelectedDates([date]);
  }, []);

  const handleMouseEnter = useCallback((day: number, month: number) => {
    if (isDragging.current) {
      lastDay.current = [Number(day), Number(month)];
      const date = createDateKey(day, month);
      dragState.current = [dragState.current[0], date];
      setSelectedDates(dragState.current);
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    lastDay.current = null;
    isDragging.current = false;
    if (dragState.current?.length == 1) {
      const [month, day] = dragState.current[0].split('-').map(Number);
      const targetDate = new Date(year, month, day);
      onDateClickHandler?.(targetDate);
    } else if (dragState.current && dragState.current?.length > 1) {
      const [month1, day1] = getMonthAndDayFromDateKey(dragState.current[0]);
      const [month2, day2] = getMonthAndDayFromDateKey(dragState.current[1]);
      let targetDate1 = createNewDate(year, month1, day1);
      let targetDate2 = createNewDate(year, month2, day2);
      if (targetDate1 > targetDate2) {
        [targetDate1, targetDate2] = [targetDate2, targetDate1];
      }
      onDateDraggedHandler?.([targetDate1, targetDate2]);
    }
    dragState.current = [];
    setSelectedDates(null);
  }, [year, onDateClickHandler, onDateDraggedHandler]);

  const handleDateClick = useCallback(
    (day: number, month: number) => {
      const date = createNewDate(year, month, day);
      onDateClickHandler?.(date);
    },
    [year, onDateClickHandler],
  );

  const findDateObjectByDateKey = useCallback(
    (dateKey: string, year: number) => {
      const [month, day] = getMonthAndDayFromDateKey(dateKey);
      const targetDate = createNewDate(year, month, day);

      if (!isValidDate(targetDate, day)) {
        return { isValid: false };
      }

      const isSelected = selectedDays.find(({ startdate, lastdate }) => {
        const endDate = lastdate || startdate;
        return targetDate >= startdate && targetDate <= endDate;
      });

      if (!isSelected) {
        return null;
      }

      const differenceInDays = getDifferenceBetweenDates(
        isSelected.startdate,
        isSelected.lastdate,
      );
      return { isSelected, differenceInDays };
    },
    [selectedDays],
  );

  const getIsInDraggedDates = useCallback(
    (dateKey: string, year: number) => {
      if (!onDateDraggedHandler || !selectedDates?.length) return false;

      const [startMonth, startDay] = getMonthAndDayFromDateKey(
        selectedDates[0],
      );
      let startDateObj = createNewDate(year, startMonth, startDay);

      const [endMonth, endDay] = getMonthAndDayFromDateKey(
        selectedDates.at(-1) ?? selectedDates[0],
      );
      let endDateObj = createNewDate(year, endMonth, endDay);

      const [month, day] = getMonthAndDayFromDateKey(dateKey);
      const currentDateObj = createNewDate(year, month, day);

      if (startDateObj > endDateObj) {
        [startDateObj, endDateObj] = [endDateObj, startDateObj];
      }

      return currentDateObj >= startDateObj && currentDateObj <= endDateObj;
    },
    [selectedDates, year, onDateDraggedHandler],
  );

  return (
    <div
      className='calendar-grid'
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <FirstRow31Days year={year} onYearChange={onYearChange} />
      {months.map((month, mIndex) => (
        <Row key={mIndex} className='month-row'>
          <FirstColumnAllMonths month={month} index={mIndex} />
          {Array.from({ length: 31 }, (_, dayIndex) => {
            const dateKey = `${mIndex}-${dayIndex + 1}`;
            const res = findDateObjectByDateKey(dateKey, year);
            const currentDate = getDateFromDateKey(dateKey, year);
            const isDragged = getIsInDraggedDates(dateKey, year);
            const isInDisabledDates = Boolean(
              disabledDates.find(date =>
                areDatesSameIgnoringTime(date, currentDate),
              ),
            );
            const isDisabled =
              isDateInPast(currentDate) ||
              res?.isValid == false ||
              isInDisabledDates;

            const isSelected = res?.isSelected;
            const differenceInDays = res?.differenceInDays;

            return (
              <RenderDateCells
                key={dateKey}
                date={dayIndex + 1}
                month={mIndex}
                isDisabled={isDisabled}
                isInDisabledDates={isInDisabledDates}
                isSelected={isSelected}
                differenceInDays={differenceInDays}
                handleDateClick={handleDateClick}
                handleMouseDown={handleMouseDown}
                handleMouseEnter={handleMouseEnter}
                handleMouseUp={handleMouseUp}
                onDelete={onDelete}
                onEdit={onEdit}
                isDragged={isDragged}
              />
            );
          })}
        </Row>
      ))}
    </div>
  );
};

const FirstRow31Days = React.memo(
  ({
    year,
    onYearChange = () => {},
  }: {
    year: number;
    onYearChange?: (year: number) => any;
  }) => {
    const yearOptions = useMemo(() => {
      const years = [];
      for (let i = year + 3; i >= year - 3; i--) {
        years.push(i);
      }
      return years;
    }, [year]);

    return (
      <Row className='days-row'>
        <Select
          style={{ width: 60 }}
          value={year}
          suffixIcon={<DownOutlined style={{ color: 'white' }} />}
          onChange={onYearChange}
          popupMatchSelectWidth={false}
          popupClassName='custom-dropdown'
        >
          {yearOptions.map(yr => (
            <Select.Option key={yr} value={yr} className='year-selector'>
              {yr}
            </Select.Option>
          ))}
        </Select>
        {Array.from({ length: 31 }, (_, i) => (
          <Col span={2} key={i} className='day-header'>
            {i + 1}
          </Col>
        ))}
      </Row>
    );
  },
);

const FirstColumnAllMonths = React.memo(
  ({ index, month }: { index: number; month: string }) => {
    return (
      <Col
        style={{
          width: '60px',
          display: 'flex',
          justifyContent: 'space-around',

          userSelect: 'none',
        }}
        className='month-label'
      >
        <span className='month-number'>{index + 1}</span>
        <span className='month-name'>{month}</span>
      </Col>
    );
  },
);

const RenderDateCells = React.memo<IRenderDateCells>(
  ({
    date,
    month,
    isSelected,
    differenceInDays,
    handleDateClick,
    handleMouseDown,
    isInDisabledDates,
    isDisabled,
    handleMouseEnter,
    handleMouseUp,
    onEdit,
    onDelete,
    isDragged,
  }) => {
    const Icon = isSelected?.Icon;
    const isLastDateOfEvent =
      isSelected &&
      isSelected?.lastdate?.getDate() == date &&
      isSelected?.lastdate?.getMonth() == month;
    const isFirstDateOfEvent =
      isSelected &&
      isSelected?.startdate?.getDate() == date &&
      isSelected?.startdate?.getMonth() == month;
    const isMiddleDateOfEvent = isSelected && !isLastDateOfEvent;

    return (
      <Tooltip
        title={isInDisabledDates ? 'Date not disponsible' : undefined}
        overlayClassName='custom-tooltip'
        overlayInnerStyle={{
          backgroundColor: '#fff',
          color: 'black',
          borderRadius: '12px',
        }}
        color='white'
      >
        <Col
          span={2}
          style={{
            backgroundColor: isSelected
              ? isSelected.color
              : isDisabled
                ? '#e2e3dd'
                : isDragged
                  ? '#134a36'
                  : '',
            borderLeft: isSelected ? 'none' : '',
            borderRight: isMiddleDateOfEvent ? 'none' : '',
            opacity: isMiddleDateOfEvent ? 0.5 : 1,
            userSelect: isSelected ? 'none' : undefined,
          }}
          onClick={() => {
            if (isDisabled) return;
            if (!isSelected) handleDateClick(date, month);
          }}
          className={`day-cell`}
          onMouseDown={() => {
            if (isDisabled) return;
            if (!isSelected) handleMouseDown(date, month);
          }}
          onMouseEnter={e => {
            if (isDisabled) {
              e.stopPropagation();
              return;
            }
            if (!isSelected) handleMouseEnter(date, month);
          }}
          onMouseUp={() => {
            if (isDisabled) return;
            if (!isSelected) handleMouseUp();
          }}
        >
          {/* Check if there's an event on this day */}

          {isLastDateOfEvent ? (
            <React.Fragment>
              {differenceInDays &&
              differenceInDays > 2 &&
              differenceInDays / 2 - 2 < date ? (
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '11px',
                    opacity: 0.9,
                    fontWeight: 600,
                    left: ` ${(-100 * (differenceInDays % 30)) / 2}%`,
                  }}
                >
                  {isSelected?.status}
                </span>
              ) : null}

              <Badge color={isSelected.color}>
                {Icon && (
                  <HoverWrapper
                    Icon={isSelected.Icon}
                    onDelete={
                      !isDisabled && !isSelected?.disableDelete
                        ? () => {
                            onDelete?.(isSelected);
                          }
                        : undefined
                    }
                    onEdit={
                      onEdit && !isDisabled
                        ? () => {
                            onEdit?.(isSelected);
                          }
                        : undefined
                    }
                  />
                )}
              </Badge>
            </React.Fragment>
          ) : null}
        </Col>
      </Tooltip>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function to prevent unnecessary re-renders
    return (
      prevProps.isSelected === nextProps.isSelected &&
      prevProps.isDisabled === nextProps.isDisabled &&
      prevProps.isDragged === nextProps.isDragged &&
      prevProps.differenceInDays === nextProps.differenceInDays
    );
  },
);
