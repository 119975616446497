import { Button, Col, Image, List, Row, Typography } from 'antd';
import { useState } from 'react';
import {
  useGlobalActions,
  useGlobalContext,
} from '../../../global-context/GlobalContext';
import { useCreateSpecialOrder } from '../../../hooks/graphql/useCreateSpecialOrder';
import { useUpdateSpecialOrder } from '../../../hooks/graphql/useUpdateSpecialOrder';
import { productInCart } from '../../../hooks/utils/useOrderDetails';
import { isoStringToLocalDate } from '../../../utils/calendarUtility';
import { showToast } from '../../../utils/commonUtility';
import {
  CartOrderConfirmation,
  ConfirmationModal,
} from '../../requests/confirmation-modal/ConfirmationModal';
import { QuantitySelectorHorizontal } from '../quantity-selector/QuantitySelector';
import './CheckoutPage.scss';

const { Text, Title } = Typography;

const baseUrl = process.env.REACT_APP_BASE_URL_IMAGE;
const CheckoutPage = ({
  selectedDate,
  deliveryCharges,
}: {
  selectedDate: {
    date: string;
    day: string;
  } | null;
  deliveryCharges: number;
}) => {
  const specialOrderData = useGlobalContext(c => c.specialOrder);
  const cartItems = useGlobalContext(c => c.specialOrder.cartItems);
  const [confirmationProps, setConfirmationProps] = useState<boolean>(false);
  const data = Array.from(cartItems.values());
  const { setExistingOrderOnDay } = useGlobalActions();
  const { createSpecialOrder, createOrderLoading } = useCreateSpecialOrder();
  const { updateSpecialOrder, updateOrderLoading } = useUpdateSpecialOrder();
  const totalOrderPricing = useGlobalContext(c => c.specialOrder.totalCartCost);

  if (!data.length) {
    return <strong>No items added in cart</strong>;
  }
  const placeOrder = () => {
    if (specialOrderData.specialOrderId) {
      updateSpecialOrder(
        specialOrderData.specialOrderId,
        data.map(o => ({
          productId: o.productId,

          quantity: o.cartQuantity,
        })),
        res => {
          if (res?.updateCompanySpecialOrder?.data?.specialOrderId) {
            setExistingOrderOnDay(data, specialOrderData.specialOrderId ?? 0);
            showToast('order-updated', 'info');
          } else {
            showToast('something-wrong', 'error');
          }
        },
      );
    } else {
      createSpecialOrder(
        isoStringToLocalDate(selectedDate?.date).toISOString() ?? '',
        Number(selectedDate?.day) ?? 1,
        data.map(o => ({
          productId: o.productId,
          quantity: o.cartQuantity,
        })),
        res => {
          if (res?.createCompanySpecialOrder?.data?.specialOrderId) {
            setConfirmationProps(true);
            showToast('order-placed', 'info');
          } else {
            showToast('something-wrong', 'error');
          }
        },
      );
    }
  };

  return (
    <div className='checkout-container'>
      <div className='checkout-list-section'>
        <CheckoutListHeader />
        <RenderCheckoutList data={data} />
      </div>
      <div className='checkout-list-placeorder'>
        <div className='checkout-placeorder-heading'>CART TOTAL</div>
        <div className='checkout-placeorder-total'>
          <span>Cart Value</span>
          <span>CHF {totalOrderPricing.toFixed(2)}</span>
        </div>
        <div className='checkout-placeorder-total'>
          <span>Delivery Charges</span>
          <span>CHF {deliveryCharges}</span>
        </div>
        <div className='checkout-placeorder-total'>
          <span>Total</span>
          <span>
            CHF
            {(Number(totalOrderPricing) + Number(deliveryCharges)).toFixed(2)}
          </span>
        </div>
        <Button
          className='checkout-placeorder-btn'
          onClick={placeOrder}
          loading={createOrderLoading || updateOrderLoading}
          disabled={Boolean(
            specialOrderData.specialOrderId &&
              !specialOrderData.isExistingCartUpdated,
          )}
        >
          {specialOrderData.specialOrderId ? 'Update Order' : 'Confirm Order'}
        </Button>
        <ConfirmationModal
          isOpen={confirmationProps}
          setOpen={(bool: boolean) => {
            setConfirmationProps(bool);
          }}
        >
          <CartOrderConfirmation />
        </ConfirmationModal>
      </div>
    </div>
  );
};

const CheckoutListHeader = () => (
  <Row
    style={{
      fontWeight: 'bold',
      fontSize: 14,
      textAlign: 'center',
      marginBottom: 16,
    }}
  >
    <Col style={{ fontWeight: 'bold' }} span={5}></Col>
    <Col style={{ fontWeight: 'bold' }} span={6}>
      Product
    </Col>
    <Col style={{ fontWeight: 'bold' }} span={4}>
      Price
    </Col>
    <Col style={{ fontWeight: 'bold' }} span={4}>
      Quantity
    </Col>
    <Col style={{ fontWeight: 'bold' }} span={4}>
      Price
    </Col>
  </Row>
);

const RenderCheckoutList = ({ data }: { data: productInCart[] }) => {
  return (
    <List
      dataSource={data}
      renderItem={product => (
        <>
          <List.Item key={product.productId}>
            <Row style={{ width: '100%' }} align='middle' gutter={16}>
              <Col span={5}>
                <Image
                  src={`${baseUrl}${product.productImage}`}
                  alt={product.productName}
                  width={90}
                  height={90}
                  style={{ borderRadius: 8 }}
                />
              </Col>
              <Col span={6}>
                <Title level={5} style={{ margin: 0 }}>
                  {product.productName}
                </Title>
              </Col>
              <Col span={4}>
                <Text>{`CHF ${product.price.toFixed(2)}`}</Text>
              </Col>
              <Col span={4}>
                <Row justify='center' align='middle'>
                  <QuantitySelectorHorizontal
                    quantity={product.cartQuantity}
                    foodInfo={product}
                  />
                </Row>
              </Col>
              <Col span={4}>
                <Text
                  style={{ fontWeight: 'bold' }}
                >{`CHF ${product.quantityCost.toFixed(2)}`}</Text>
              </Col>
            </Row>
          </List.Item>
        </>
      )}
    />
  );
};
export default CheckoutPage;
