import { gql, useMutation } from '@apollo/client';

const CUSTOMER_SIGN_IN = gql`
  mutation EmployeeSignIn(
    $email: String!
    $password: String!
    $appName: String!
  ) {
    employeeSignIn(
      signInInput: { email: $email, appName: $appName, password: $password }
    ) {
      message
      status
      statusCode
      error
      data {
        token
        skipMobileVerification
        timeBased2faUri
        timeBased2faSecretKey
        company {
          companyId
          abbreviatedCompanyName
          logo
        }
        user {
          employeeId
          email
          firstName
          familyName
          userName
          mobile
          dialCode
          emailVerificationStatus
          mobileVerificationStatus
          feelEatPhoneNumber
          active
          companyId
          accessRights
          empRfidNo
          isPreferencesEnable
          isAllergiesEnable
          enableFavouriteProductNotification
        }
      }
    }
  }
`;
const useCustomerSignIn = () => {
  const [handleSignIn, { error, data, loading }] =
    useMutation(CUSTOMER_SIGN_IN);

  const signIn = (email: string, appName: string, password: string) => {
    return handleSignIn({
      variables: {
        email,
        appName,
        password,
      },
    });
  };

  return { signIn, error, data, loading };
};

export default useCustomerSignIn;
