import { Modal, notification } from 'antd';
import { tolgee } from '../App';

type ToastTypes =
  | 'fail-login'
  | 'add-to-cart-error'
  | 'event-reserved'
  | 'event-updated'
  | 'event-deleted'
  | 'success-login'
  | 'error_startdate'
  | 'session-expired'
  | 'order-placed'
  | 'order-updated'
  | 'voucher-success'
  | 'something-wrong';

export const showToast = (
  keyName: ToastTypes,
  type: 'info' | 'error' | 'success',
  desc: string = '',
  placement:
    | 'topLeft'
    | 'top'
    | 'topRight'
    | 'bottom'
    | 'bottomLeft'
    | 'bottomRight' = 'topRight',
) => {
  switch (type) {
    case 'error':
      notification.error({
        message: tolgee.t(keyName),
        description: desc.length ? tolgee.t(desc) : undefined,
        placement: placement,
      });
      break;
    case 'info':
      notification.info({
        message: tolgee.t(keyName),
        description: desc.length ? tolgee.t(desc) : undefined,
        placement: placement,
      });
      break;
    case 'success':
      notification.success({
        message: tolgee.t(keyName),
        description: desc.length ? tolgee.t(desc) : undefined,
        placement: placement,
      });
      break;
    default:
      console.log('default case');
  }
};

let navigateFunction: ((path: string) => void) | null = null;

export function setNavigateFunction(navigate: (path: string) => void) {
  navigateFunction = navigate;
}

export function navigateTo(path: string) {
  if (navigateFunction) {
    navigateFunction(path);
  } else {
    console.error('Navigate function not set!');
  }
}

export const openConfirmationPopup = ({
  title,
  onOkCB,
  onCancelCB,
}: {
  title: string;
  onOkCB?: Function;
  onCancelCB?: Function;
}) => {
  Modal.confirm({
    title: title,
    okText: 'Yes',
    width: '300px',

    okButtonProps: {
      style: {
        backgroundColor: '#275747',
        padding: '0.8rem 1.8rem',
        borderRadius: '24px',
      },
    },
    cancelButtonProps: {
      style: {
        backgroundColor: '#275747',
        color: '#fff',
        padding: '1rem 2rem',
        borderRadius: '24px',
      },
    },
    cancelText: 'No',

    styles: {
      body: {
        backgroundColor: '#f2f6f5',
        padding: '30px',
        borderRadius: '24px',
      },
      content: { padding: '0px', backgroundColor: 'transparent' },
    },
    style: { padding: '0px', backgroundColor: 'none' },
    onOk() {
      onOkCB?.();
    },
    onCancel() {
      onCancelCB?.();
    },
  });
};

export const getCompanyIds = (
  companyNames: string[],
  associatedCompanies: any,
) => {
  return associatedCompanies
    .filter((comp: any) =>
      companyNames.find((val: string) => val === comp.companyName),
    )
    .map((com: any) => com.companyId);
};

export const toggleItemInArray = <T,>(array: T[], item: T): T[] =>
  array.includes(item) ? array.filter(i => i !== item) : [...array, item];
