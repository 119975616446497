import { gql, useMutation } from '@apollo/client';

const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($employeeId: Int!, $active: Boolean!) {
    updateUserStatus(
      updateUserArg: { employeeId: $employeeId, active: $active }
    ) {
      message
      status
      statusCode
      error
      data {
        isUpdated
      }
    }
  }
`;

export const useUpdateUserStatus = () => {
  const [updateUserStatus, { error, data, loading }] =
    useMutation(UPDATE_USER_STATUS);

  const updateStatus = (
    employeeId: number,
    active: boolean,

    onCompleteCb?: (data: any) => any,
  ) => {
    updateUserStatus({
      variables: {
        employeeId: employeeId,
        active: active,
      },
      onCompleted: onCompleteCb,
    });
  };

  return { updateStatus, error, data, loading };
};
