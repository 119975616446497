import { gql, useLazyQuery } from '@apollo/client';

const GET_MASTER_ALLERGIES = gql`
  query getMasterAllergies {
    getMasterAllergies {
      message
      status
      statusCode
      error
      data {
        allergyId
        titleEn
        titleFr
        titleDe
        synonym
        imageEn
        imageFr
        imageDe
        unSelectedImage
        unSelectedImageEn
        unSelectedImageFr
        unSelectedImageDe
        status
        order
        createdAt
        updatedAt
      }
    }
  }
`;

const useGetMasterAllergies = () => {
  const [getMasterAllergies, { error, loading, data }] =
    useLazyQuery(GET_MASTER_ALLERGIES);

  return {
    getMasterAllergies,
    error,
    masterAllergies: data ? data?.getMasterAllergies?.data : [],
    loading,
  };
};

export default useGetMasterAllergies;
