import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useGlobalContext } from '../../global-context/GlobalContext';
import { LINKS } from '../../routes/Links';

interface PrivateRouteProps {
  children: ReactNode;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const isUserAuthenticated = useGlobalContext(
    data => data.auth.isAuthenticated,
  );

  if (isUserAuthenticated) {
    return <>{children}</>;
  }
  return <Navigate to={LINKS.LOGIN.build()} />;
};
