import { ConfigProvider } from 'antd';
import App from 'antd/lib/app';
import React from 'react';
import { FeelEatThemeLight } from '../enums/feeleat-theme';

const ApplicationBranding = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConfigProvider theme={FeelEatThemeLight}>
      <App>{children}</App>
    </ConfigProvider>
  );
};

export default ApplicationBranding;
