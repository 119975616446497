class LocalStorageService {
  // Set an item in Local Storage
  setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  // Get an item from Local Storage
  getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : null;
  }

  // Remove an item from Local Storage
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // Clear all items in Local Storage
  clear(): void {
    localStorage.clear();
  }
}

const localStorageService = new LocalStorageService();
export default localStorageService;
