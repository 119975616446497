import { gql, useLazyQuery } from '@apollo/client';
// @typescript-eslint/no-unused-vars
// TypeScript type for DatePeriod
type DatePeriod = {
  startDate: string;
  endDate: string;
};

// TypeScript type for Pagination
type Pagination = {
  page: number;
  limit: number;
};

// TypeScript type for Sort
type Sort = {
  name: string; // Field by which to sort, e.g., "startDate", "endDate"
  order: 'ASC' | 'DESC'; // Sorting order: Ascending or Descending
};

const TASTING_BOOKINGS = gql`
  query GetTastingBookings(
    $filter: JSON
    $pagination: Pagination
    $sort: Sort
  ) {
    getTastingBookings(
      getTastingBookingsArgs: {
        filter: $filter
        pagination: $pagination
        sort: $sort
      }
    ) {
      message
      status
      statusCode
      error
      data {
        reportType
        base64String
        count
        page
        pageSize
        unavailableDates
        list {
          id
          companyId
          status
          maxCapacity
          comments
          bookingDate
          createdAt
          modifiedAt
          createdByResourceType
          createdByResourceId
          createdByResourceEmail
        }
      }
    }
  }
`;

export const useGetTastingBookings = () => {
  const [getTastingBookings, { error, data, loading }] = useLazyQuery(
    TASTING_BOOKINGS,
    { fetchPolicy: 'no-cache' },
  );

  return {
    getTastingBookings,
    error,
    data,
    loading,
  };
};
