import { Calendar } from 'antd';
import React from 'react';

import { Dayjs } from 'dayjs';
import { ICalendarCommon } from '../../../utils/calendarUtility';
import { displayCenter } from '../../../utils/styleobjects';
import { HoverWrapper } from '../hover-wrapper/HoverWrapper';
import './CalendarMobile.scss';

export const CalendarMobile: React.FC<ICalendarCommon> = ({
  selectedDays,
  onDateClickHandler,
  onMonthChange,
  onEdit,
  onDelete,
}) => {
  const isSelectedDate = (date: Dayjs) => {
    const day = date.date();
    const month = date.month();
    const year = date.year();
    const requiredDate = new Date(year, month, day);

    const isSelected = selectedDays.find(({ startdate, lastdate }) => {
      const endDate = lastdate || startdate; // If lastdate is not provided, consider it same as startdate
      return requiredDate >= startdate && requiredDate <= endDate;
    });

    return isSelected;
  };

  // Custom date cell rendering to allow selection
  const dateCellRender = (date: Dayjs) => {
    const isSelected = isSelectedDate(date);
    const Icon = isSelected?.Icon;
    return (
      <div
        key={date.date()}
        onClick={() => {
          if (isSelected) return;
          onDateClickHandler?.(date.toDate());
        }}
        style={{
          ...displayCenter,
          backgroundColor: isSelected ? isSelected.color : undefined,
          height: '100%',
          width: '100%',
        }}
      >
        {isSelected && Icon ? (
          <HoverWrapper
            Icon={isSelected.Icon}
            onDelete={() => {
              onDelete?.(isSelected);
            }}
            onEdit={
              onEdit
                ? () => {
                    onEdit?.(isSelected);
                  }
                : undefined
            }
          />
        ) : (
          date.date()
        )}
      </div>
    );
  };

  return (
    <div>
      <Calendar
        mode='month'
        fullCellRender={dateCellRender}
        onPanelChange={date => {
          const year = date.year();
          const month = date.month();
          console.log('Year:', year, 'Month:', month);
          onMonthChange?.(year, month);
        }}
        fullscreen={false} // Makes it mobile-friendly
      />
    </div>
  );
};
