import { useState } from 'react';

const useApp = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState<boolean>(false);
  const [openeRemarksdPanelType, setOpenedRemarksPanelType] = useState<
    'CALENDAR' | 'EVENTS' | 'NONE'
  >('NONE');

  const state = {
    isSidePanelOpen,
    openeRemarksdPanelType,
  };

  const actions = {
    setIsSidePanelOpen,
    setOpenedRemarksPanelType,
  };
  return { state, actions };
};

export default useApp;
