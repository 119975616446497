export class Link {
  _template: string;

  /**
   * Constructor is private to avoid creating of unrefactorable links
   * @param template
   */
  private constructor(template: string) {
    this._template = template;
  }

  get template(): string {
    return this._template;
  }

  /**
   * creates root in link
   * @param itemTemplate e.g. ":userId" or "users"
   */
  static ofRoot(itemTemplate: string) {
    return this.ofParent(null, itemTemplate);
  }

  /**
   * adds to parent link and returns new link
   * @param link
   * @param itemTemplate
   */
  static ofParent(link: Link | null, itemTemplate: string): Link {
    return new Link(`${link ? link.template : ''}/${itemTemplate}`);
  }

  public build(params?: { [key: string]: string | number }): string {
    let link = this.template;
    params = params ? params : {};
    for (const param of Object.keys(params)) {
      link = link.replace(`:${param}`, params[param].toString());
    }
    return link;
  }

  buildWithOrigin(params?: { [key: string]: string | number }) {
    return window.origin + this.build(params);
  }
}

export enum PARAMS {
  INVITATION_CODE = 'invitation_code',
  ENCODED_EMAIL_AND_CODE = 'email_and_code',
  SERVICE_TYPE = 'serviceType',
  PROJECT_ID = 'projectId',
  LANGUAGE_ID = 'languageId',
  API_KEY_ID = 'apiKeyId',
  PAT_ID = 'patId',
  USER_ID = 'userID',
  VERIFICATION_CODE = 'verificationCode',
  ORGANIZATION_SLUG = 'slug',
  TRANSLATION_ID = 'translationId',
  PLAN_ID = 'planId',
}

export class LINKS {
  static ROOT = Link.ofRoot('');

  static LOGIN = Link.ofRoot('login');

  static HOME = Link.ofRoot('home');

  static CALENDAR = Link.ofRoot('calendar');

  static USER_LIST = Link.ofRoot('user-list');

  static SPECIAL_ORDER = Link.ofRoot('special-order');

  static ANALYTICS = Link.ofRoot('analytics');

  static VOUCHER = Link.ofRoot('voucher');

  static REQUESTS = Link.ofRoot('requests');

  static LOGOUT = Link.ofRoot('logout');

  // static EMAIL_VERIFICATION = Link.ofParent(
  //     LINKS.LOGIN,
  //     'verify_email/' + p(PARAMS.USER_ID) + '/' + p(PARAMS.VERIFICATION_CODE)
  // );

  // static RESET_PASSWORD = Link.ofRoot('reset_password');

  // static RESET_PASSWORD_WITH_PARAMS = Link.ofParent(
  //     LINKS.RESET_PASSWORD,
  //     p(PARAMS.ENCODED_EMAIL_AND_CODE)
  // );
}
