import { gql, useLazyQuery } from '@apollo/client';

const GET_COMPANY = gql`
  query GetCompany {
    getCompany {
      message
      status
      statusCode
      error
      data {
         companyId
            companyCategoryId
            abbreviatedCompanyName
            companyCode
            companyColourCode
            isArchived
            logo
      }
    }
  }
`;

const useGetCompany = () => {
  const [getCompanyProfile, { error, loading, data }] =
    useLazyQuery(GET_COMPANY);

  return {
    getCompanyProfile,
    error,
    data: data?.getCompany?.data,
    loading,
  };
};

export default useGetCompany;
