// routes.ts
import { lazy } from 'react';
import Analytics from '../pages/analytics/Analytics';
import { LogOut } from '../pages/logout/logout';
import SpecialOrder from '../pages/special-order/SpecialOrder';
import { TestingRequests } from '../pages/testing-requests/TestingRequests';
import UserList from '../pages/user-list/UserList';
import Voucher from '../pages/voucher/Voucher';
import { LINKS } from './Links';

const HomePage = lazy(() => import('../pages/home/Home'));
const CalendarPage = lazy(() => import('../pages/calendar/Calendar'));

const routes = [
  { path: LINKS.LOGIN.build(), element: <HomePage />, protected: false },
  { path: LINKS.HOME.build(), element: <HomePage />, protected: true },
  { path: LINKS.CALENDAR.build(), element: <CalendarPage />, protected: true },
  {
    path: LINKS.REQUESTS.build(),
    element: <TestingRequests />,
    protected: true,
  },
  {
    path: LINKS.USER_LIST.build(),
    element: <UserList />,
    protected: true,
  },
  {
    path: LINKS.SPECIAL_ORDER.build(),
    element: <SpecialOrder />,
    protected: true,
  },
  {
    path: LINKS.ANALYTICS.build(),
    element: <Analytics />,
    protected: true,
  },
  {
    path: LINKS.VOUCHER.build(),
    element: <Voucher />,
    protected: true,
  },
  { path: LINKS.LOGOUT.build(), element: <LogOut />, protected: true },
];

export default routes;
