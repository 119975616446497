import Sider from 'antd/es/layout/Sider';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGlobalActions,
  useGlobalContext,
} from '../../../global-context/GlobalContext';
import { LINKS } from '../../../routes/Links';
import { FeelEatMenu } from '../../feel-eat/feel-eat-menu/FeelEatMenu';
import { sidebarBottomMenu, sidebarMainMenu } from '../side-menu/sideMenus';
import './MainNavigation.scss';

export const MainNavigation = ({ isSignedIn }: { isSignedIn: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clearUserSession, setIsSidePanelOpen } = useGlobalActions();
  const isSidePanelOpen = useGlobalContext(c => c.app.isSidePanelOpen);
  const selectedKey = useMemo(() => {
    const currentPath = location.pathname;
    const menuItem = sidebarMainMenu.find(menu => menu.link === currentPath);
    return menuItem ? [menuItem.key] : ['1']; // Default to '1' if no match found
  }, [location.pathname]);

  if (!isSignedIn) {
    return <></>;
  }

 

  const navigateToPage = (item: any) => {
    const { link } = sidebarMainMenu.find(menu => menu.key === item.key) || {};
    if (link) {
      navigate(link);
    }
  };

  const handleSideBarBottomMenuClick = (item: any) => {
    const { key } =
      sidebarBottomMenu[0].children.find(
        (menu: any) => menu.key === item.key,
      ) || {};
    if (key === '2') {
      clearUserSession();
      navigate(LINKS.LOGIN.build());
    }
  };

  return (
    <div className={classNames('sidebar-container')}>
      <Sider
        className={classNames('sidebar', {
          'sidebar-expanded': isSidePanelOpen,
        })}
        collapsible
        collapsed={!isSidePanelOpen}
        onCollapse={() => setIsSidePanelOpen(e => !e)}
      >
        <div className='logo' style={{ color: 'white', padding: '2rem' }}>
          <button
            className='hamburger-icon'
            aria-label='menu'
            onClick={() => {
              setIsSidePanelOpen(e => !e);
            }}
          >
            &#9776;
          </button>
          <span
            style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}
          >
            {' '}
            {isSidePanelOpen && 'Menu'}
          </span>
        </div>

        <FeelEatMenu
          theme='dark'
          mode='inline'
          style={{
            marginTop: '20px',
            textAlign: 'center',
          }}
          onClick={navigateToPage}
          className='sidebar-main-menu'
          selectedKeys={selectedKey}
          items={sidebarMainMenu}
        />
        <FeelEatMenu
          className='sidebar-setting-menu'
          mode='vertical'
          items={sidebarBottomMenu}
          selectedKeys={selectedKey}
          onClick={handleSideBarBottomMenuClick}
        />
      </Sider>
    </div>
  );
};
