import { gql, useMutation } from '@apollo/client';
import { toLocalDate } from '../../utils/calendarUtility';

const UPDATE_STAFFING_STATUS = gql`
  mutation UpdateCompanyStaffingStatus(
    $statusid: Int!
    $status: String!
    $reason: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    updateCompanyStaffingStatus(
      updateCompanyStaffingStatusesArgs: {
        id: $statusid
        status: $status
        reason: $reason
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      message
      status
      statusCode
      error
      data {
        isUpdate
      }
    }
  }
`;

export const useUpdateStaffingStatus = () => {
  const [updateCompanyStaffingStatus, { error, data, loading }] = useMutation(
    UPDATE_STAFFING_STATUS,
  );

  const updateStatus = (
    statusid: number,
    status: string,
    reason: string,
    startDate: Date,
    endDate: Date,
    onCompleteCb?: (data: any) => any,
  ) => {
    updateCompanyStaffingStatus({
      variables: {
        statusid: statusid,
        status: status,
        reason: reason,
        startDate: toLocalDate(startDate),
        endDate: toLocalDate(endDate),
      },
      onCompleted: onCompleteCb,
    });
  };

  return { updateStatus, error, data, loading };
};
