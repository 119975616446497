import { Drawer } from 'antd';
import React, { ReactNode } from 'react';
import useIsMobile from '../../../hooks/utils/useIsMobile';
import './DrawerCommon.scss';
interface IDrawerCommon {
  isVisible: boolean;
  setVisibility: (bool: boolean) => any;
  placement: 'left' | 'right' | 'top' | 'bottom';
  height?: number;
  children?: ReactNode;
  styles?: any;
}
const DrawerCommon: React.FC<IDrawerCommon> = ({
  isVisible,
  setVisibility,
  placement,
  height,
  children,
  styles,
}) => {
  const isMobile = useIsMobile();

  const onClose = () => {
    setVisibility(false);
  };

  return (
    <Drawer
      placement={placement ?? 'right'}
      closeIcon={<></>}
      closable={true}
      onClose={onClose}
      open={isVisible}
      styles={{
        header: { display: 'none' },
        body: { padding: 0 },
        wrapper: { ...styles },
      }}
      height={height ?? '100vh'} // Full height of the screen
      width={isMobile ? '100%' : '40%'}
      className='common-header-container'
    >
      {isVisible ? children : null}
    </Drawer>
  );
};

export default DrawerCommon;
