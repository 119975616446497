import {
  AlertOutlined,
  BarChartOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { T } from '@tolgee/react';
import { Avatar } from 'antd';
import { useGlobalContext } from '../../../global-context/GlobalContext';
import { LINKS } from '../../../routes/Links';
const baseUrl = process.env.REACT_APP_BASE_URL_IMAGE;
export const sidebarMainMenu = [
  {
    key: '1',
    label: <T keyName='nav-home' />,
    icon: <HomeOutlined />,
    link: LINKS.HOME.build(),
  },
  {
    key: '2',
    label: <T keyName='nav-calendar' />,
    icon: <CalendarOutlined />,
    link: LINKS.CALENDAR.build(),
  },
  {
    key: '3',
    label: <T keyName='nav-book-testing' />,
    icon: <CalendarOutlined />,
    link: LINKS.REQUESTS.build(),
  },
  {
    key: '4',
    label: <T keyName='nav-employee-list' />,
    icon: <UserOutlined />,
    link: LINKS.USER_LIST.build(),
  },
  {
    key: '5',
    label: <T keyName='nav-sales-statistics' />,
    icon: <BarChartOutlined />,
    link: LINKS.ANALYTICS.build(),
  },
  {
    key: '6',
    label: <T keyName='nav-grants' />,
    icon: <WalletOutlined />,
    link: LINKS.VOUCHER.build(),
  },
  {
    key: '7',
    label: <T keyName='nav-special-orders' />,
    icon: <AlertOutlined />,
    link: LINKS.SPECIAL_ORDER.build(),
  },
  {
    key: '8',
    label: <T keyName='nav-delivers' />,
    icon: <FieldTimeOutlined />,
    link: LINKS.CALENDAR.build(),
  },
  {
    key: '9',
    label: <T keyName='nav-support' />,
    icon: <SettingOutlined />,
    link: null,
  },
];

export const CompanyAvatar = () => {
  const companyData = useGlobalContext(c => c.auth.companyInfo);
  return (
    <>
      <Avatar
        style={{
          backgroundColor: '#ffffff',
        }}
      >
        <img src={`${baseUrl}${companyData?.logo}`} />
      </Avatar>
      <span className='userName'> {companyData?.abbreviatedCompanyName}</span>
    </>
  );
};

export const sidebarSettingMenu = [
  {
    key: '1',
    label: <CompanyAvatar />,
  },
  {
    key: '2',
    label: (
      <span className='user_links'>
        <T keyName='sign-out' />
      </span>
    ),
  },
];

export const sidebarBottomMenu = [
  {
    label: <T keyName='nav-user-settings' />,
    key: '10',
    icon: <UserOutlined />,
    children: sidebarSettingMenu,
  },
];
