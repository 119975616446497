import { useMemo } from 'react';
import {
  convertISOToDate,
  makeTastingObject,
} from '../../utils/calendarUtility';
import { useBookTasting } from '../graphql/useBookTasting';
import { useCancelTasting } from '../graphql/useCancelTasting';
import { useGetTastingBookings } from '../graphql/useGetTastingBookings';
import { useUpdateTastingReservation } from '../graphql/useUpdateTastingReservation';
let byDateVariables: {
  filter: { datePeriod: { startDate: string; endDate: string } };
};
let pageVariables: {
  pagination: {
    page: number;
    limit: number;
  };
  sort: {
    name: string;
    order: 'ASC' | 'DESC';
  };
};
export const useTastingService = () => {
  const { getTastingBookings, data: tastingDataApi } = useGetTastingBookings();
  const {
    getTastingBookings: getTastingBookingByPage,
    data: tastingDataByPageApi,
  } = useGetTastingBookings();
  const { updateStaffingReservation, loading: updateReservationLoading } =
    useUpdateTastingReservation();
  const { bookTasting, loading: bookTastingLoading } = useBookTasting();
  const { cancelTasting, loading: cancelBookingLoading } = useCancelTasting();

  const getBookingsBtwDates = (startDate: Date, endDate: Date) => {
    byDateVariables = {
      filter: {
        datePeriod: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      },
    };
    getTastingBookings({ variables: byDateVariables });
  };
  const getBookingsByPage = (page: number, limit: number = 10) => {
    pageVariables = {
      pagination: {
        page: page,
        limit: limit,
      },
      sort: {
        name: 'modifiedAt',
        order: 'DESC',
      },
    };
    getTastingBookingByPage({ variables: pageVariables });
  };

  const refetchGetBookingsBtwDates = () => {
    if (byDateVariables) getTastingBookings({ variables: byDateVariables });
  };

  const tastingData = useMemo(() => {
    const list = tastingDataApi?.getTastingBookings?.data?.list;
    if (!list) {
      return [];
    }
    return list.map(makeTastingObject);
  }, [tastingDataApi]);
  const disabledDates = useMemo(() => {
    const list = tastingDataApi?.getTastingBookings?.data?.unavailableDates;
    if (!list) {
      return [];
    }
    return list.map(convertISOToDate);
  }, [tastingDataApi]);

  const tastingDataByPage = useMemo(() => {
    const list = tastingDataByPageApi?.getTastingBookings?.data?.list;
    if (!list) {
      return [];
    }
    return list.map(makeTastingObject);
  }, [tastingDataByPageApi]);

  return {
    getBookingsBtwDates,
    tastingData,
    refetchGetBookingsBtwDates,
    updateStaffingReservation,
    tastingDataByPage,
    getBookingsByPage,
    disabledDates,
    bookTasting,
    bookTastingLoading,
    cancelBookingLoading,
    cancelTasting,
    updateReservationLoading,
  };
};
