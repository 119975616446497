import { useState } from 'react';
import { createProvider } from '../components/common/CreateProvider';
import useAuth from '../hooks/utils/UseAuthService';
import useApp from '../hooks/utils/useAppService';
import useSpecialOrder from '../hooks/utils/useOrderDetails';
import { globalContext } from './GlobalActions';

export const [GlobalContext, useGlobalActions, useGlobalContext] =
  createProvider(() => {
    const [globalError, setGlobalError] = useState<any>();

    const auth = useAuth();
    const app = useApp();
    const specialOrder = useSpecialOrder();

    const actions = {
      setGlobalError,
      ...auth.actions,
      ...app.actions,
      ...specialOrder.actions,
    };

    globalContext.actions = actions;

    const contextData = {
      globalError,
      auth: auth.state,
      app: app.state,
      specialOrder: specialOrder.state,
    };

    return [contextData, actions];
  });
