import { gql, useLazyQuery } from '@apollo/client';

const GET_ASSOCIATED_COMPANIES = gql`
  query GetAssociatedCompanies {
    getAssociatedCompanies {
      message
      status
      statusCode
      error
      data {
        abbreviatedCompanyName
        companyName
        companyId
      }
    }
  }
`;

const useGetAssociatedCompanies = () => {
  const [getAssociatedCompanies, { error, data, loading }] = useLazyQuery(
    GET_ASSOCIATED_COMPANIES,
  );
  return {
    getAssociatedCompanies,
    error,
    associatedCompanies: data?.getAssociatedCompanies?.data,
    loading,
  };
};

export default useGetAssociatedCompanies;
