import { useState } from 'react';
import { toggleItemInArray } from '../../utils/commonUtility';

export const useSelection = <T>(initialValue: T[]) => {
  const [selectedItems, setSelectedItems] = useState<T[]>(initialValue);

  const toggleSelection = (item: T) => {
    setSelectedItems(prev => toggleItemInArray(prev, item));
  };

  return { selectedItems, toggleSelection, setSelectedItems };
};
