import { gql, useLazyQuery } from '@apollo/client';

const GET_PREFERENCES = gql`
  query getPreferences {
    getPreferences {
      message
      status
      statusCode
      error
      data {
        preferencesId
        titleEn
        titleFr
        titleDe
        image
        imageEn
        imageFr
        imageDe
        unSelectedImage
        unSelectedImageEn
        unSelectedImageFr
        unSelectedImageDe
        status
        createdAt
        updatedAt
      }
    }
  }
`;

const useGetOrderPreferences = () => {
  const [getOrderPreferences, { error, loading, data }] =
    useLazyQuery(GET_PREFERENCES);

  return {
    getOrderPreferences,
    error,
    orderPreferences: data ? data?.getPreferences?.data : [],
    loading,
  };
};

export default useGetOrderPreferences;
