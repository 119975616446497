import { gql, useMutation } from '@apollo/client';

const UPDATE_TASTING_RESERVATION = gql`
  mutation UpdateTastingReservation($statusid: Int!, $reason: String!) {
    updateTastingReservation(
      updateTastingReservationArgs: { id: $statusid, reason: $reason }
    ) {
      message
      status
      statusCode
      error
      data {
        isUpdate
      }
    }
  }
`;

export const useUpdateTastingReservation = () => {
  const [updateTastingReservationQuery, { error, data, loading }] = useMutation(
    UPDATE_TASTING_RESERVATION,
  );

  const updateStaffingReservation = (
    statusid: number,
    reason: string,
    onCompleteCb?: (data: any) => any,
  ) => {
    updateTastingReservationQuery({
      variables: {
        statusid: statusid,
        reason: reason,
      },
      onCompleted: onCompleteCb,
    });
  };

  return { updateStaffingReservation, error, data, loading };
};
