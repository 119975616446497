import { useTranslate } from '@tolgee/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import remarkIcon from '../../../assets/icons/Calendrier-remarks.svg';
import { useGlobalActions } from '../../../global-context/GlobalContext';
import { LINKS } from '../../../routes/Links';
import './RemarksDrawer.scss';

export const RemarksDrawer = () => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const { setOpenedRemarksPanelType } = useGlobalActions();
  console.log(currentRoute);
  if (currentRoute == LINKS.CALENDAR._template) {
    return (
      <RemarksMenuMaker
        icon={<img src={remarkIcon} />}
        title='nav-calendar'
        setRemarksPanel={() => {
          setOpenedRemarksPanelType(prev =>
            prev == 'CALENDAR' ? 'NONE' : 'CALENDAR',
          );
        }}
      />
    );
  } else if (currentRoute == LINKS.REQUESTS._template) {
    return (
      <RemarksMenuMaker
        icon={<img src={remarkIcon} />}
        title='nav-book-testing'
        setRemarksPanel={() => {
          setOpenedRemarksPanelType(prev => {
            return prev == 'EVENTS' ? 'NONE' : 'EVENTS';
          });
        }}
      />
    );
  }

  return <></>;
};

export const RemarksMenuMaker = ({
  icon,
  title,
  setRemarksPanel,
}: {
  icon: React.ReactNode;
  title: string;
  setRemarksPanel: () => any;
}) => {
  const { t } = useTranslate();

  return (
    <div className='remarksmenu' onClick={setRemarksPanel}>
      <div className='remarks-icon'>{icon}</div>
      <div className='remarks-title'>
        <div className='remarks-maintitle'>{t(title)}</div>
        <div className='remarks-desc'>{t('remarks')}</div>
      </div>
    </div>
  );
};
