import { CloseOutlined } from '@ant-design/icons';
import { T, useTranslate } from '@tolgee/react';
import { Button, Col, DatePicker, Form, Radio, Row, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useState } from 'react';
import calendarIcon from '../../../assets/icons/calendar.svg';
import { ISelectedDays } from '../../../utils/calendarUtility';
import { displayCenter } from '../../../utils/styleobjects';
import './AddEventForm.scss';

export const AddEventForm: React.FC<{
  fromDate: Date;
  toDate: Date;
  selectedStaffingDay?: ISelectedDays;
  loading: boolean;
  closeForm: () => any;
  onFormSubmit: (formValues: any) => any;
}> = ({
  fromDate,
  toDate,
  onFormSubmit,
  selectedStaffingDay,
  closeForm,
  loading,
}) => {
  const { t } = useTranslate();

  const [radio, setradio] = useState(selectedStaffingDay?.status ?? 'Close');
  const [fromToDates, setFromToDates] = useState<[Date, Date]>([
    fromDate,
    toDate,
  ]);

  const onFinish = (values: any) => {
    values['startDate'] = fromToDates[0];
    values['endDate'] = fromToDates[1];
    values['status'] = radio;
    onFormSubmit(values);
  };
  const handleRatioChange = (e: any) => {
    setradio(e.target.value);
  };
  return (
    <div className='addevent-form-container'>
      <Form
        style={{}}
        layout='vertical'
        onFinish={onFinish}
        initialValues={{ comments: selectedStaffingDay?.comments ?? '' }}
      >
        <div className='title'>
          <img className='title-img' src={calendarIcon} />

          <span>
            <T keyName='nav-calendar' />
          </span>
        </div>
        <div className='closeicon'>
          <CloseOutlined onClick={() => closeForm()} />
        </div>
        <Form.Item name='startDate' style={{ marginBottom: '0' }}>
          <CustomDatePicker
            defaultDate={fromToDates[0]}
            placeholder='De'
            onDateChange={(fromDate: Date) => {
              setFromToDates(prev => [fromDate, prev[1]]);
            }}
          />
        </Form.Item>

        <Form.Item name='endDate' style={{ marginBottom: '1.4rem' }}>
          <CustomDatePicker
            defaultDate={fromToDates[1]}
            placeholder='a'
            onDateChange={(toDate: Date) => {
              setFromToDates(prev => [prev[0], toDate]);
            }}
            disablePrevDates={fromToDates[0]}
          />
        </Form.Item>

        <div style={{ marginBottom: '10px' }}>
          <Space
            direction='vertical'
            size='small'
            style={{ rowGap: '5px', marginBottom: '1.4rem' }}
          >
            <div>
              <Radio
                value='Close'
                checked={radio === 'Close'}
                onClick={handleRatioChange}
                className='radio-one'
              />
              {t('closed')}
            </div>
            <div>
              <Radio
                value='Reduction of workforce'
                checked={radio === 'Reduction of workforce'}
                onClick={handleRatioChange}
                className='radio-two'
              />
              % {t('workforce-reduction')}
            </div>
            <div>
              <Radio
                value='Vacation'
                checked={radio === 'Vacation'}
                onClick={handleRatioChange}
                className='radio-three'
              />
              {t('holidays')}{' '}
            </div>
            <div>
              <Radio
                value='Public holidays'
                checked={radio === 'Public holidays'}
                onClick={handleRatioChange}
                className='radio-four'
              />
              {t('public-holidays')}
            </div>
          </Space>
        </div>

        <Form.Item label='Comments' name='comments'>
          <TextArea
            rows={3}
            // defaultValue={selectedStaffingDay?.comments ?? ''}
            style={{ lineHeight: '1.5' }}
            placeholder=''
          />
        </Form.Item>

        <Form.Item style={{ ...displayCenter }}>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            block
            className='submit-btn'
          >
            {loading ? '' : 'Submit'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export const CustomDatePicker: React.FC<{
  placeholder: string;
  defaultDate?: Date;
  onDateChange: (changedDate: Date) => any;
  disablePrevDates?: Date;
}> = ({
  placeholder,
  defaultDate,
  onDateChange,
  disablePrevDates = new Date(),
}) => {
  const disabledDate = (current: any) => {
    // Disable dates before the specified date
    return current && current.isBefore(disablePrevDates, 'day');
  };
  return (
    <Row align='middle'>
      <Col
        style={{
          position: 'absolute',
          zIndex: '9',
          marginLeft: placeholder && '4px',
        }}
      >
        {placeholder && (
          <span
            style={{
              marginRight: '8px',
              fontSize: '12px',

              lineHeight: '32px',
              fontWeight: 'bold',
              color: '#275747',
            }}
          >
            {placeholder + ' '}:
          </span>
        )}
      </Col>
      <Col flex='auto'>
        <DatePicker
          value={dayjs(defaultDate)}
          format='DD/MM/YYYY'
          style={{
            height: '30px',
            border: 'none',

            paddingLeft: placeholder && '26px',
            paddingTop: '11px',
            backgroundColor: '#f1f2ed',
          }}
          disabledDate={disabledDate}
          className='custom-date-picker'
          onChange={date => {
            onDateChange(date.toDate());
          }}
          popupClassName='custom-calendar'
        />
      </Col>
    </Row>
  );
};
