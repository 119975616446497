import { gql, useMutation } from '@apollo/client';

const CANCEL_TASTING = gql`
  mutation CancelTastingReservation($id: Int!) {
    cancelTastingReservation(cancelTastingReservationArgs: { id: $id }) {
      message
      status
      statusCode
      error
      data {
        isCancelled
      }
    }
  }
`;

export const useCancelTasting = () => {
  const [cancelTastingRequest, { error, data, loading }] =
    useMutation(CANCEL_TASTING);

  const cancelTasting = (
    statusId: number,
    onCompleteCb?: (data: any) => any,
  ) => {
    cancelTastingRequest({
      variables: {
        id: statusId,
      },
      onCompleted: onCompleteCb,
    });
  };

  return { cancelTasting, error, data, loading };
};
