import { useTranslate } from '@tolgee/react';
import { Col, Row, Space, Typography } from 'antd';
import {
  TastingStatuses,
  tastingStutusesData,
} from '../requests-calendar-outline/RequestsCalendarOutline';
import './RequestHeader.scss';

const { Title, Text } = Typography;

interface LegendItemProps {
  icon: React.ReactNode;
  label: string;
}

// Legend Item Component with TypeScript typing for props
const LegendItem: React.FC<LegendItemProps> = ({ icon, label }) => (
  <Col>
    <Space>
      {icon}
      <Text color='#134a36'>{label}</Text>
    </Space>
  </Col>
);

export const RequestsHeader: React.FC = () => {
  const { t } = useTranslate();
  return (
    <div className='tastingheader-container'>
      <div className='tastingheader-header'>
        <h1 className='tastingheader-heading'> {t('tasting-heading1')}</h1>
        <h1 className='tastingheader-heading'>{t('tasting-heading2')}</h1>
        <p className='tastingheader-subheading'>{t('tasting-subtext')}</p>
      </div>
      <Row justify='center' style={{ margin: '2rem' }}>
        <Space size='large'>
          <LegendItem
            icon={
              <TastingIcon
                color={tastingStutusesData[TastingStatuses.PENDING].color}
              />
            }
            label='Pending Request'
          />
          <LegendItem
            icon={
              <TastingIcon
                color={tastingStutusesData[TastingStatuses.CONFIRMED].color}
              />
            }
            label='Confirmed Request'
          />
          <LegendItem
            icon={
              <TastingIcon
                color={tastingStutusesData[TastingStatuses.CANCELED].color}
              />
            }
            label='Cancelled Request'
          />
          <LegendItem
            icon={<TastingIcon color='#e2e3dd' hideContent={true} />}
            label='Date not disponible'
          />
        </Space>
      </Row>
    </div>
  );
};

export const TastingIcon = ({
  color,
  hideContent = false,
}: {
  color?: string;
  hideContent?: boolean;
}) => (
  <div style={{ backgroundColor: color, padding: '4px', display: 'flex' }}>
    <svg
      fill='#000000'
      height='18px'
      width='14px'
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      {!hideContent && (
        <>
          <g>
            <g>
              <path
                d='M97.576,4.664c-49.267,0-86.259,51.229-86.259,97.033v40.889c0,41.49,29.651,76.217,68.782,84.301v268.2
			c0,9.341,7.573,16.913,16.914,16.913s16.914-7.573,16.914-16.913V227.082c39.652-7.675,69.909-42.636,69.909-84.496v-40.889
			C183.835,55.892,146.844,4.664,97.576,4.664z M150.008,142.585c0,28.813-23.619,52.253-52.432,52.253
			c-28.813,0-52.432-23.441-52.432-52.253v-40.889c0-28.629,23.487-63.206,52.432-63.206s52.432,34.577,52.432,63.206V142.585z'
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d='M352.97,0c-9.341,0-16.914,7.573-16.914,16.914V163.07c0,11.335-8.778,20.808-20.114,20.808h-3.565V16.914
			C312.378,7.573,304.805,0,295.464,0s-16.914,7.573-16.914,16.914v166.964h-4.421c-11.335,0-20.385-9.472-20.385-20.808V16.914
			C253.744,7.573,246.172,0,236.831,0s-16.914,7.573-16.914,16.914V163.07c0,29.988,24.224,54.635,54.212,54.635h4.421v276.649
			c0,9.341,7.573,16.914,16.914,16.914s16.914-7.573,16.914-16.914V217.705h3.565c29.988,0,53.941-24.646,53.941-54.635V16.914
			C369.884,7.573,362.312,0,352.97,0z'
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d='M460.263,12.334c-9.157-8.062-21.761-9.95-32.88-4.925c-11.118,5.024-18.033,15.725-18.033,27.926v459.017
			c0,9.341,7.573,16.914,16.914,16.914s16.914-7.573,16.914-16.914v-170.69l49.831-32.682c4.763-3.127,7.675-8.441,7.675-14.14
			V101.614C500.681,67.451,485.904,34.909,460.263,12.334z M466.854,267.711l-23.679,15.486V42.796
			c14.658,15.768,23.679,36.824,23.679,58.818V267.711z'
              />
            </g>
          </g>
        </>
      )}
    </svg>
  </div>
);
