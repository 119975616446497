import { gql, useMutation } from '@apollo/client';
import { toLocalDate } from '../../utils/calendarUtility';

const BOOK_TASTING = gql`
  mutation BookTasting(
    $maxCapacity: Int!
    $comments: String
    $bookingDate: DateTime!
  ) {
    bookTasting(
      bookTastingArgs: {
        maxCapacity: $maxCapacity
        comments: $comments
        bookingDate: $bookingDate
      }
    ) {
      message
      status
      statusCode
      error
      data {
        isCreated
      }
    }
  }
`;

export const useBookTasting = () => {
  const [bookTastingQuery, { error, data, loading }] =
    useMutation(BOOK_TASTING);

  const bookTasting = (
    bookingDate: Date,
    comments: string,
    maxCapacity: number,
    onCompleteCb?: (data: any) => any,
  ) => {
    bookTastingQuery({
      variables: {
        bookingDate: toLocalDate(bookingDate),
        comments: comments,
        maxCapacity: maxCapacity,
      },
      onCompleted: onCompleteCb,
    });
  };

  return { bookTasting, error, data, loading };
};
