import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import './SidebarSlider.scss';

import { RefObject } from 'react';

export const useClickOutside = (
  ref: RefObject<HTMLElement | undefined>,
  callback: () => void,
) => {
  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
};

export const SidebarSlider: React.FC<{
  children: ReactNode;
  width: string;
  styles?: CSSProperties;
  visible: boolean;

  setVisibility: (visibility: boolean) => any;
}> = ({ children, width, styles, visible, setVisibility }) => {
  const [currentWidth, setCurrentWidth] = useState('0px');
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => {
    setTimeout(() => {
      setVisibility(false);
    }, 200);
  });
  useEffect(() => {
    if (width && visible) {
      setTimeout(() => {
        setCurrentWidth(width);
      }, 100);
    } else if (!visible) {
      setCurrentWidth('0px');
    }
  }, [width, visible]);

  return (
    <>
      <div className='overlay'></div>
      <div
        className='sidebar-slider'
        id='sidebar-slider'
        ref={ref}
        style={{ ...styles, width: currentWidth }}
      >
        {visible && children}
      </div>
    </>
  );
};
