import React from 'react';
import './OrderPreferences.scss';

interface OrderPreferences {
  preferencesId: string;
  titleEn: string;
  titleFr: string;
  titleDe: string;
  imageEn: string;
  imageFr: string;
  imageDe: string;
  unSelectedImage: string;
  unSelectedImageEn: string;
  unSelectedImageFr: string;
  unSelectedImageDe: string;
  status: string;
}

interface Props {
  orderPreferences: OrderPreferences[];
  language: 'en' | 'fr' | 'de';
  onMealTypeSelect: (id: string) => void;
  selectedMealType: any;
}

const baseUrl = process.env.REACT_APP_BASE_URL_IMAGE;

const OrderPreferences: React.FC<Props> = ({
  orderPreferences,
  language,
  onMealTypeSelect,
  selectedMealType,
}) => {
  return (
    <div className='preference-container'>
      {orderPreferences.map(preference => {
        const isActive = selectedMealType.includes(preference.titleEn);
        const image =
          language === 'en'
            ? isActive
              ? preference.imageEn
              : preference.unSelectedImageEn
            : language === 'fr'
              ? isActive
                ? preference.imageFr
                : preference.unSelectedImageFr
              : isActive
                ? preference.imageDe
                : preference.unSelectedImageDe;

        const title =
          language === 'en'
            ? preference.titleEn
            : language === 'fr'
              ? preference.titleFr
              : preference.titleDe;

        return (
          <div
            key={preference.preferencesId}
            className={`preference-item ${isActive ? 'preference-container__active' : 'preference-container__inactive'}`}
            onClick={() => onMealTypeSelect(preference.titleEn)}
          >
            <img
              src={`${baseUrl}${image}`}
              alt={title}
              className='preference-icon'
            />
          </div>
        );
      })}
    </div>
  );
};

export default OrderPreferences;
