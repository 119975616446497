import { gql, useLazyQuery } from '@apollo/client';
const GET_COMPANY_USERS = gql`
  query getCompanyUsers($filter: JSON, $pagination: Pagination, $sort: Sort) {
    getCompanyUsers(
      getCompanyUsersArgs: {
        filter: $filter
        pagination: $pagination
        sort: $sort
      }
    ) {
      message
      status
      statusCode
      error
      data {
        count
        page
        pageSize
        list {
          employeeId
          empRfidNo
          companyId
          companyName
          employeeSalutation
          firstName
          familyName
          addressFirstLine
          addressSecondLine
          houseNumber
          town
          postCode
          canton
          userName
          email
          mobile
          dailCode
          feelEatPhoneNumber
          shellCallNumber
          active
          isFeelEatEmployee
          country
          mobileVerificationStatus
          emailVerificationStatus
          accessRights
          locationId
          cardNumber
          cardActive
          cardIssueDate
          recieveMail
          dob
          joiningDate
          relievingDate
          workingHours
          weeklyWorkingHours
          balancedBreakDays
          balancedHours
          balancedHolidays
          balancedPublicHolidays
          salaryPaymentMethod
          isCorporateAccount
          allowCreditBalance
          lastBonusTransferedFor
          department
          test
          dontSendReceiptEmail
          sendFridgeLoginAlert
          dontSendPaymentChaserAlert
          lastPaymentChaserAlert
          lastPaymentChaserMailSendDate
          colorCode
          verifyEmailDomain
          nfcBadgeSerialNumber
          isDeleted
          isPreferencesEnable
          isAllergiesEnable
          enableFavouriteProductNotification
          skipLogin2FA
          isDummyAccount
          creationDate
        }
      }
    }
  }
`;

export const useGetCompanyUsers = () => {
  const [getCompanyUsers, { error, data, loading }] =
    useLazyQuery(GET_COMPANY_USERS);

  return {
    getCompanyUsers,
    error,
    companyUsers: data?.getCompanyUsers?.data,
    loading,
  };
};
