import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useGlobalContext } from '../../global-context/GlobalContext';
import { LINKS } from '../../routes/Links';

interface PublicOnlyRouteProps {
  children: ReactNode;
}

export const PublicOnlyRoute: React.FC<PublicOnlyRouteProps> = ({
  children,
}) => {
  const isUserAuthenticated = useGlobalContext(
    data => data.auth.isAuthenticated,
  );

  if (isUserAuthenticated) {
    return <Navigate to={LINKS.HOME.build()} />;
  }
  return <>{children}</>;
};
