import { Layout } from 'antd';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../global-context/GlobalContext';
import { useEffectOnce } from '../../../hooks/utils/useEffectOnce';
import { AppRouter } from '../../../routes/AppRouter';
import routes from '../../../routes/Routes';
import { setNavigateFunction } from '../../../utils/commonUtility';
import Header from '../../header/Header';
import { MainNavigation } from '../main-navigation/MainNavigation';
import './LayoutComponent.scss';

const { Content } = Layout;

function LayoutComponent() {
  const isSignedIn = useGlobalContext(c => c.auth.isAuthenticated);
  const isSidePanelOpen = useGlobalContext(c => c.app.isSidePanelOpen);
  const navigate = useNavigate();

  useEffectOnce(() => {
    setNavigateFunction(navigate);
  });

  return (
    <Layout className='main-layout' hasSider>
      <Header isSignedIn={isSignedIn} />
      <MainNavigation isSignedIn={isSignedIn} />
      <Content
        className={classNames('content-container', {
          'is-login': isSignedIn,
          'sidebar-open': isSignedIn && isSidePanelOpen,
        })}
      >
        <AppRouter routes={routes} />
      </Content>
    </Layout>
  );
}

export default LayoutComponent;
