import { gql, useMutation } from '@apollo/client';

type VoucherOrderItemsArgs = {
  voucherAmount: number;
  voucherCount: number;
};

const CREATE_COUPONS = gql`
  mutation CreateVoucherRequest(
    $voucherRequestItems: [CreateVoucherRequestItemArgs!]!
  ) {
    createVoucherRequest(
      createVoucherRequestArgs: { voucherRequestItems: $voucherRequestItems }
    ) {
      message
      status
      statusCode
      error
      data {
        voucherRequestId
      }
    }
  }
`;

export const useCreateVoucher = () => {
  const [createCoupons, { error, data, loading }] = useMutation(CREATE_COUPONS);

  const createVoucherRequest = (
    voucherRequestItems: VoucherOrderItemsArgs[],
    onCompleteCb?: (data: any) => any,
  ) => {
    createCoupons({
      variables: {
        voucherRequestItems,
      },
      onCompleted: onCompleteCb,
    });
  };

  return { createVoucherRequest, error, data, createOrderLoading: loading };
};
