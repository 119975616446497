import React from 'react';
import {
  useGlobalActions
} from '../../../global-context/GlobalContext';
import { SpecialProduct } from '../../../interfaces/ISpecialOrder';
import './QuantitySelector.scss';

interface QuantitySelectorProps {
  quantity: number;
  foodInfo: SpecialProduct;

}

const QuantitySelector: React.FC<QuantitySelectorProps> = ({
  quantity,
  foodInfo

}) => {
  const { addToCart, removeFromCart } = useGlobalActions();
  const increaseQuantityChange = () => {
    addToCart(foodInfo);
  };
  const decreaseQuantityChange = () => {
    removeFromCart(foodInfo.productId);
  };

  return (
    <div className='quantity-selector'>
      <button
        onClick={() => decreaseQuantityChange()}
        disabled={quantity <= 0}
        className='quantity-selector-button-negative'
      >
        -
      </button>
      <span>{quantity}</span>
      <button
        className='quantity-selector-button-positive'
        onClick={() => increaseQuantityChange()}
      >
        +
      </button>
    </div>
  );
};
export const QuantitySelectorHorizontal: React.FC<QuantitySelectorProps> = ({
  quantity,
  foodInfo

}) => {
  const { addToCart, removeFromCart } = useGlobalActions();
  const increaseQuantityChange = () => {
    addToCart(foodInfo);
  };
  const decreaseQuantityChange = () => {
    removeFromCart(foodInfo.productId);
  };

  return (
    <div className='quantity-selector-horizontal'>
      <button
        onClick={() => decreaseQuantityChange()}
        disabled={quantity <= 0}
        className='quantity-selector-button-negative'
      >
        -
      </button>
      <span>{quantity}</span>
      <button
        className='quantity-selector-button-positive'
        onClick={() => increaseQuantityChange()}
      >
        +
      </button>
    </div>
  );
};
export default QuantitySelector;
