import {} from 'antd';
import { Navigate } from 'react-router-dom';
import {
  useGlobalActions,
  useGlobalContext,
} from '../global-context/GlobalContext';
import { useEffectOnce } from '../hooks/utils/useEffectOnce';
import Loader from './common/loader/Loader';

const LoggedInContainer = ({ children }: { children: JSX.Element }) => {
  const { companyError, companyLoading, needToVerifyToken } = useGlobalContext(
    data => data.auth,
  );
  const { verifyUser } = useGlobalActions();
  useEffectOnce(() => {
    verifyUser();
  });

  if (needToVerifyToken || companyLoading) {
    return <Loader />;
  }

  if (companyError) {
    return <Navigate to='/login' />;
  }

  return children;
};

export default LoggedInContainer;
