import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useGlobalActions } from '../../global-context/GlobalContext';

const GET_SPECIAL_ORDER = gql`
  query getSpecialOrderProducts(
    $weekDate: DateTime!
    $day: Int!
    $allergyIds: [Int!]!
  ) {
    getSpecialOrderProducts(
      getSpecialOrderProductsArgs: {
        weekDate: $weekDate
        day: $day
        allergyIds: $allergyIds
      }
    ) {
      message
      status
      statusCode
      error
      data {
        productId
        specialOrderQuantity
        purchaseOrderId
        specialOrderId
        productImage
        productName
        productDescription
        productType
        mealTypeImage
        mealType
        productFeatures {
          name
          image
        }
        jarSize
        price
        allergicItems {
          name
          image
        }
        isAllergic
        productDietary {
          healthLabels
          calories
          protein
          gras
          nutritions {
            label
            quantity
            dailyQuantity
          }
        }
        productIngredients
      }
    }
  }
`;

export const useGetSpecialOrderProducts = () => {
  const [getSpecialOrderProducts, { error, data, loading }] =
    useLazyQuery(GET_SPECIAL_ORDER);
  const { setExistingOrderOnDay } = useGlobalActions();

  useEffect(() => {
    setExistingOrderOnDay(data ? data?.getSpecialOrderProducts?.data : []);
  }, [data]);

  return {
    getSpecialOrderProducts,
    error,
    specialOrderProducts: data ? data?.getSpecialOrderProducts?.data : [],
    loading,
  };
};
