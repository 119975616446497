import { useTranslate } from '@tolgee/react';
import React, { useState } from 'react';
import LeftArrow from '../../../assets/icons/left-arrow-new.svg';
import LessIcon from '../../../assets/icons/less.svg';
import MoreIcon from '../../../assets/icons/more.svg';
import Warning from '../../../assets/icons/red-warning.svg';
import { SpecialProduct } from '../../../interfaces/ISpecialOrder';

import './ProductCard.scss';

interface ProductCardProps {
  foodInfo: SpecialProduct;
  setIsDrawerOpen: (isOpen: boolean) => void;
}

const baseUrl = process.env.REACT_APP_BASE_URL_IMAGE;

const ProductCard: React.FC<ProductCardProps> = ({
  foodInfo,
  setIsDrawerOpen,
}) => {
  const [expandIngredients, setExpandIngredients] = useState(true);
  const [expandNutrition, setExpandNutrition] = useState(false);
  const [healthLabel, setHealthLabel] = useState(false);
  const { t } = useTranslate();

  return (
    <div className='product-card'>
      <div className='product-card__banner'>
        <h1 className='product-card__banner-name'>{foodInfo.productName}</h1>
        <div
          className='product-card__banner-img'
          onClick={() => setIsDrawerOpen(false)}
        >
          <img src={LeftArrow} />
        </div>
      </div>
      <div className='product-card__image'>
        <div className='product-card__alert'>
          <img
            src={`${baseUrl}${foodInfo.productImage}`}
            alt={foodInfo.productName}
          />
        </div>
        {foodInfo.isAllergic && (
          <div className='product-card-allergy'>
            {foodInfo.allergicItems.map((allergy, index) => (
              <img
                src={`${baseUrl}${allergy.image}`}
                alt={allergy.name}
                className='product-card-allergy__img'
                key={index}
              />
            ))}
          </div>
        )}
        <div className='product-card__image-container-header'>
          {foodInfo.isAllergic && <img src={Warning} />}
        </div>
      </div>

      {/* Scrollable Content Section */}
      <div className='product-card__content'>
        {/* Allergic Items */}
        <div className='product-card__allergic'>
          <div className='product-card__allergic-icons'>
            {foodInfo.productFeatures.map((item, index) => (
              <img
                key={index}
                src={`${baseUrl}${item.image}`}
                alt={item.name}
                className='product-card__icons-img'
              />
            ))}
          </div>
          <p className='product-card__allergic-size'>{foodInfo.jarSize}</p>
        </div>
        <p className='product-card__description'>
          {foodInfo.productDescription}
        </p>

        {/* Ingredients Section */}
        <div className='product-card__section'>
          <div
            className='product-card__header'
            onClick={() => setExpandIngredients(!expandIngredients)}
          >
            <h3>{t('ingredients')}</h3>
            {!expandIngredients && <img src={MoreIcon} />}
            {expandIngredients && <img src={LessIcon} />}
          </div>
          {expandIngredients && (
            <div className='product-card__ingredients'>
              {foodInfo.productIngredients.map((ingredient, index) => (
                <span key={index}>
                  {ingredient}
                  {index < foodInfo.productIngredients.length - 1 && ', '}
                </span>
              ))}
            </div>
          )}
        </div>

        {/* Nutrition Section */}
        <div className='product-card__section'>
          <div
            className='product-card__header'
            onClick={() => setExpandNutrition(!expandNutrition)}
          >
            <h3>{t('nutritional-value')}</h3>
            {!expandNutrition && <img src={MoreIcon} />}
            {expandNutrition && <img src={LessIcon} />}{' '}
          </div>
          {expandNutrition && (
            <div className='product-card__nutrition'>
              <h3>{t('quantity-per-serving')}</h3>
              <div className='product-card__nutrition-micro-container'>
                <div className='product-card__nutrition-micro-container-single'>
                  <div></div>
                  <div>
                    <p>{t('protein')}</p>
                    <p>{foodInfo.productDietary.protein}</p>
                  </div>
                </div>
                <div>
                  <div></div>
                  <div>
                    <p>{t('fat')}</p>
                    <p>{foodInfo.productDietary.gras}</p>
                  </div>
                </div>
                <div>
                  <div></div>
                  <div>
                    <p>{t('calories')}</p>
                    <p>{foodInfo.productDietary.calories}</p>
                  </div>
                </div>
              </div>
              <ul>
                {foodInfo.productDietary.nutritions.map((nutrition, index) => (
                  <li key={index}>
                    {nutrition.label}: {nutrition.quantity}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className='product-card__section'>
          <div
            className='product-card__header'
            onClick={() => setHealthLabel(!healthLabel)}
          >
            <h3>{t('health-label')}</h3>
            {!healthLabel && <img src={MoreIcon} />}
            {healthLabel && <img src={LessIcon} />}
          </div>
          {healthLabel && (
            <div className='product-card__ingredients'>
              {foodInfo.productDietary.healthLabels.map((ingredient, index) => (
                <li key={index}>{ingredient} </li>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
