import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  makeVar,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { tokenService } from '../services/TokenService';
import { globalErrorHandler } from './globalErrorHandler';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_GRAPHQL_API_PREFIX_URL}`;

const httpLink = createHttpLink({
  uri: baseUrl,
});

export const languageVar = makeVar('en'); // Default language is 'en'

export const changeLanguageGraphQL = (newLanguage: string) => {
  languageVar(newLanguage);
};

const errorLink = onError((error: ErrorResponse) => globalErrorHandler(error));

const authLink = setContext(async (_, { headers }) => {
  const token = tokenService.getToken() || headers?.authorization;
  const currentLanguage = languageVar(); // Step 2: Access the current language

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
      'x-custom-lang': currentLanguage,
    },
  };
});

const graphqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  // add errorLink, authLink before httpLink otherwise those will not get called
  link: from([errorLink, authLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: 'cache-and-network',
      //fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      //fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export default graphqlClient;
