import { gql, useMutation } from '@apollo/client';

const DELETE_STAFFING_STATUS = gql`
  mutation DeleteCompanyStaffingStatus($id: Int!) {
    deleteCompanyStaffingStatus(
      deleteCompanyStaffingStatusesArgs: { id: $id }
    ) {
      message
      status
      statusCode
      error
      data {
        isDeleted
      }
    }
  }
`;

export const useDeleteStaffingStatus = () => {
  const [deleteCompanyStaffingStatus, { error, data, loading }] = useMutation(
    DELETE_STAFFING_STATUS,
  );

  const deleteStatus = (
    statusId: number,
    onCompleteCb?: (data: any) => any,
  ) => {
    deleteCompanyStaffingStatus({
      variables: {
        id: statusId,
      },
      onCompleted: onCompleteCb,
    });
  };

  return { deleteStatus, error, data, loading };
};
