import { useTranslate } from '@tolgee/react';
import { Modal } from 'antd';
import hatsvg from '../../../assets/icons/hat.svg';
import './ConfirmationModal.scss';

export const ConfirmationModal = ({
  isOpen,
  setOpen,
  children,
}: {
  isOpen: boolean;
  setOpen: (bool: boolean) => any;
  children: any;
}) => {
  return (
    <Modal
      open={isOpen}
      onCancel={() => setOpen(false)}
      footer={null}
      styles={{
        body: { background: '#eff3ef', padding: 0 },
        content: { padding: 0, borderRadius: '2.4rem', overflow: 'hidden' },
      }}
    >
      <div style={{ padding: '40px 10px' }}>{children}</div>
    </Modal>
  );
};

export const ReservedSuccess = ({ date }: { date: Date }) => {
  const { t } = useTranslate();
  return (
    <div className='reserve-container'>
      <div className='reserve-header'>
        <h1 className='reserve-heading'>{t('reservation-modal-title')}</h1>
        <p className='reserve-subheading'>
          {t('reservation-modal-subtext1')}
          <br></br>

          {t('reservation-modal-subtext-date')}
          <span
            style={{ fontWeight: '500' }}
          >{`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}</span>
        </p>
        <p className='reserve-subheading'>{t('reservation-modal-subtext2')}</p>
        <p className='reserve-subheading see-you'>
          {t('reservation-modal-seeyou')}
        </p>
        <div style={{ margin: '5px 0 0 0' }}>
          <img className='hat-icon' src={hatsvg}></img>
        </div>
      </div>
    </div>
  );
};

export const ReservationCancelled = () => {
  const { t } = useTranslate();
  return (
    <div className='reserve-container'>
      <div className='reserve-header'>
        <h1 className='reserve-heading'>
          {t('reservation-cancelled-modal-title')}
        </h1>
        <p className='reserve-subheading'>
          {t('reservation-cancelled-modal-subtext')}
        </p>

        <div style={{ margin: '20px 0 0 0' }}>
          <img className='hat-icon' src={hatsvg}></img>
        </div>
      </div>
    </div>
  );
};

export const CartOrderConfirmation = () => {
  const { t } = useTranslate();
  return (
    <div className='reserve-container'>
      <div className='reserve-header'>
        <h1 className='reserve-heading'>{t('confirm-order-success-title')}</h1>
        <p className='reserve-subheading'>{t('confirm-order-subtitle-1')}</p>
        <p className='reserve-subheading'>{t('confirm-order-subtitle-2')}</p>
        <p className='reserve-subheading see-you'>
          {t('confirm-order-last-msg')}
        </p>
        <div style={{ margin: '5px 0 0 0' }}>
          <img className='hat-icon' src={hatsvg}></img>
        </div>
      </div>
    </div>
  );
};

export const VoucherOrderConfirmation = () => {
  const { t } = useTranslate();
  return (
    <div className='reserve-container'>
      <div className='reserve-header'>
        <h1 className='reserve-heading'>{t('confirm-order-success-title')}</h1>
        <p className='reserve-subheading'>{t('confirm-order-subtitle-1')}</p>
        <p className='reserve-subheading'>{t('confirm-order-subtitle-2')}</p>
        <p className='reserve-subheading see-you'>
          {t('confirm-order-last-msg')}
        </p>
        <div style={{ margin: '5px 0 0 0' }}>
          <img className='hat-icon' src={hatsvg}></img>
        </div>
      </div>
    </div>
  );
};
