import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { FormatIcu } from '@tolgee/format-icu';
import { LanguageDetector, Tolgee, TolgeeProvider } from '@tolgee/react';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './assets/sass/_reset.scss';
import ApplicationBranding from './components/ApplicationBranding';
import LayoutComponent from './components/common/layout-component/LayoutComponent';
import LoggedInContainer from './components/LoggedInContainer';
import { GlobalContext } from './global-context/GlobalContext';

interface IAppProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
}
export const tolgee = Tolgee()
  .use(FormatIcu())
  .use(LanguageDetector())
  .init({
    defaultLanguage: 'en',
    fallbackLanguage: 'en',
    staticData: {
      en: () => import('./localization/i18n/en.json').then(m => m.default),
      fr: () => import('./localization/i18n/fr.json').then(m => m.default),
      de: () => import('./localization/i18n/de.json').then(m => m.default),
    },
  });
const App = ({ apolloClient }: IAppProps) => {
  return (
    <ApplicationBranding>
      <Suspense fallback={<h1>Loading</h1>}>
        <ApolloProvider client={apolloClient}>
          <TolgeeProvider tolgee={tolgee} options={{ useSuspense: false }}>
            <GlobalContext>
              <BrowserRouter>
                <LoggedInContainer>
                  <LayoutComponent />
                </LoggedInContainer>
              </BrowserRouter>
            </GlobalContext>
          </TolgeeProvider>
        </ApolloProvider>
      </Suspense>
    </ApplicationBranding>
  );
};
export default App;
