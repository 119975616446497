import { useState, useEffect } from 'react';

// Define the type for listeners (callbacks that set state)
type Listener = (isMobile: boolean) => void;

// Global state to store whether it's mobile or not
let globalIsMobile = window.innerWidth <= 768;
let listeners: Listener[] = [];

// Utility hook to detect mobile device with shared global state
const useIsMobile = (breakpoint: number = 768): boolean => {
  // Local state for each component
  const [isMobile, setIsMobile] = useState<boolean>(globalIsMobile);

  useEffect(() => {
    const handleResize = () => {
      const currentIsMobile = window.innerWidth <= breakpoint;
      if (currentIsMobile !== globalIsMobile) {
        globalIsMobile = currentIsMobile;
        listeners.forEach(listener => listener(globalIsMobile)); // Notify all listeners
      }
    };

    // Add current setState to the listeners
    listeners.push(setIsMobile);

    // Add the resize event listener only once
    if (listeners.length === 1) {
      window.addEventListener('resize', handleResize);
    }

    // Cleanup when the component unmounts
    return () => {
      listeners = listeners.filter(listener => listener !== setIsMobile);

      // Remove the resize event listener if no components are listening
      if (listeners.length === 0) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
