import React from 'react';
import './AllergyFilter.scss';

interface Allergy {
  allergyId: string;
  titleEn: string;
  titleFr: string;
  titleDe: string;
  synonym: string;
  imageEn: string;
  imageFr: string;
  imageDe: string;
  unSelectedImage: string;
  unSelectedImageEn: string;
  unSelectedImageFr: string;
  unSelectedImageDe: string;
  status: string;
}

interface Props {
  allergies: Allergy[];
  language: 'en' | 'fr' | 'de';
  onAllergiesSelect: (id: number) => void;
  selectedAllergies: any;
}

const baseUrl = process.env.REACT_APP_BASE_URL_IMAGE;

const AllergyFilter: React.FC<Props> = ({
  allergies,
  language,
  onAllergiesSelect,
  selectedAllergies,
}) => {
  return (
    <div className='allergy-container'>
      {allergies.map(allergy => {
        const isActive = selectedAllergies.includes(Number(allergy.allergyId));
        const image =
          language === 'en'
            ? isActive
              ? allergy.imageEn
              : allergy.unSelectedImageEn
            : language === 'fr'
              ? isActive
                ? allergy.imageFr
                : allergy.unSelectedImageFr
              : isActive
                ? allergy.imageDe
                : allergy.unSelectedImageDe;

        const title =
          language === 'en'
            ? allergy.titleEn
            : language === 'fr'
              ? allergy.titleFr
              : allergy.titleDe;

        return (
          <div
            key={allergy.allergyId}
            className={`allergy-item ${isActive ? 'active' : 'inactive'}`}
            onClick={() => onAllergiesSelect(Number(allergy.allergyId))}
          >
            <img
              src={`${baseUrl}${image}`}
              alt={title}
              className='allergy-icon'
            />
          </div>
        );
      })}
    </div>
  );
};

export default AllergyFilter;
