import { Button } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateVoucher } from '../../../hooks/graphql/useAddCoupons';
import { LINKS } from '../../../routes/Links';
import { showToast } from '../../../utils/commonUtility';
import {
  CartOrderConfirmation,
  ConfirmationModal,
} from '../../requests/confirmation-modal/ConfirmationModal';
import './VoucherOrder.scss';

interface Item {
  id: number;
  price: number;
  quantity: number;
}

const VoucherOrder: React.FC = () => {
  const [items, setItems] = useState<Item[]>([
    { id: 1, price: 50, quantity: 5 },
  ]);

  const navigate = useNavigate();
  const [confirmationProps, setConfirmationProps] = useState<boolean>(false);
  const { createVoucherRequest, createOrderLoading } = useCreateVoucher();
  const addItem = () => {
    setItems([...items, { id: Date.now(), price: 50, quantity: 5 }]);
  };

  const updateQuantity = (id: number, delta: number) => {
    setItems(
      items.map(item =>
        item.id === id
          ? { ...item, quantity: Math.max(0, item.quantity + delta) }
          : item,
      ),
    );
  };

  const updatePrice = (id: number, price: string) => {
    const parsedPrice = parseFloat(price);
    if (parsedPrice < 0) {
      return;
    }
    setItems(
      items.map(item =>
        item.id === id
          ? { ...item, price: isNaN(parsedPrice) ? 0 : parsedPrice }
          : item,
      ),
    );
  };

  const deleteItem = (id: number) => {
    setItems(items.filter(item => item.id !== id));
  };

  const totalQuantity = items.reduce((sum, item) => sum + item.quantity, 0);
  const totalValue = items.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0,
  );
  const addVouchers = () => {
    createVoucherRequest(
      items.map(i => ({ voucherAmount: i.price, voucherCount: i.quantity })),
      data => {
        if (data?.createVoucherRequest?.data?.voucherRequestId) {
          setConfirmationProps(true);
        } else {
          showToast('something-wrong', 'error');
        }
      },
    );
  };
  return (
    <div className='voucher-order'>
      <div className='voucher-order__left-section'>
        <table className='order-table'>
          <thead>
            <tr>
              <th>Value</th>
              <th>Quantity</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => (
              <tr key={item.id}>
                <td>
                  <input
                    type='number'
                    value={item.price}
                    onChange={e => updatePrice(item.id, e.target.value)}
                  />
                </td>
                <td>
                  <button onClick={() => updateQuantity(item.id, -1)}>-</button>
                  <span>{item.quantity}</span>
                  <button onClick={() => updateQuantity(item.id, 1)}>+</button>
                </td>
                <td>CHF {(item.price * item.quantity).toFixed(2)}</td>
                <td>
                  <button
                    className='delete-btn'
                    onClick={() => deleteItem(item.id)}
                  >
                    🗑
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className='add-btn' onClick={addItem}>
          + Add More
        </button>
      </div>
      <div className='voucher-order__right-section'>
        <div className='summary'>
          <h3>Summary</h3>
          <p>
            <span>Quantity:</span> <span>{totalQuantity}</span>
          </p>
          <p>
            <span>Total Value:</span> <span>CHF {totalValue.toFixed(2)}</span>
          </p>
          <Button
            className='confirm-btn'
            onClick={addVouchers}
            loading={createOrderLoading}
          >
            Confirm Order
          </Button>
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmationProps}
        setOpen={(bool: boolean) => {
          setConfirmationProps(bool);
          navigate(LINKS.HOME.build());
        }}
      >
        <CartOrderConfirmation />
      </ConfirmationModal>
    </div>
  );
};

export default VoucherOrder;
