import { useTranslate } from '@tolgee/react';
import AnalyticsSection from '../../components/analytics/analytics-section/AnalyticsSection';
import './Analytics.scss';
const Analytics = () => {
  const { t } = useTranslate();

  return (
    <div className='analytics-container'>
      <div className='analytics-header'>
        <h1 className='analytics-header-heading'>{t('analytics-heading-1')}</h1>
        <h1 className='analytics-header-heading'>{t('analytics-heading-2')}</h1>
        <p className='analytics-header-subheading'>
          {t('analytics-subHeading-1')}
        </p>
        <p className='analytics-header-subheading'>
          {t('analytics-subHeading-2')}
        </p>
      </div>
      <div className='analytics-section'>
        <AnalyticsSection />
      </div>
    </div>
  );
};

export default Analytics;
